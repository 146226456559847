import { useMemo } from "react";
import { notification } from "antd";
import {
   REGEX,
   Functions,
   applyReplacements
} from "../../../../../../../helpers/choiceFilterParser";
import { getForeignInputNames } from "../helpers/getForeignInputNames";
import useInputValuesByName from "./useInputValueByName";
import { comparisonOperators } from "../../../../../../../helpers/constraintParsers";

function cleanUpFilter(filter) {
   // Step 1: Remove the column filter expression from
   let result = filter.replace(REGEX.columnFilter, "").trim();

   // Step 2: Remove any leading or trailing "and" or "or"
   result = result.replace(/^(and|or)\s+|\s+(and|or)$/g, "").trim();

   return result;
}

const useEvaluateChoiceFilter = (props) => {
   const inputNames = getForeignInputNames(props.choiceFilter);
   const relatedInputs = useInputValuesByName(
      props.isRepeat
         ? inputNames.flatMap((name) => [name, `${name}/${props.repeatInstanceIndex}`])
         : inputNames
   );

   return useMemo(() => {
      if (!props.choiceFilter) {
         return props.selectQuestionChoices;
      }

      if (Object.keys(relatedInputs).length === 0 || props.selectQuestionChoices.length === 0) {
         return [];
      }

      let name = props.selectQuestionChoices.slice();
      let choiceFilter = props.choiceFilter;

      const columnFilterMatch = REGEX.columnFilter.exec(choiceFilter);
      if (columnFilterMatch?.length === 4) {
         const [, , operator] = columnFilterMatch;
         choiceFilter = cleanUpFilter(choiceFilter);

         const filteredOptions = props.selectQuestionChoices.filter((option) => {
            if (!option.choiceFilters?.length) {
               return true;
            }
            const { filterChoice } = option.choiceFilters[0];
            const question = filterChoice.questionsResponse[0];

            // eslint-disable-next-line no-eval
            return eval(
               `filterChoice.name ${comparisonOperators[operator]} relatedInputs['${question.name}']`
            );
         });

         if (!choiceFilter) {
            return filteredOptions;
         }
         name = filteredOptions;
      }

      if (props.isRepeat) {
         choiceFilter = choiceFilter.replace(
            REGEX.foreignInputValue,
            `${"${"}$1/${props.repeatInstanceIndex}}`
         );
      }

      const parsedFilter = applyReplacements(choiceFilter);

      try {
         // eslint-disable-next-line no-new-func
         const evaluate = new Function(
            ...Object.keys(Functions),
            "relatedInputs",
            "name",
            `return ${parsedFilter};`
         );
         const result = evaluate(...Object.values(Functions), relatedInputs, name);

         return Array.isArray(result) ? result : [];
      } catch (e) {
         notification.warning({
            message: "Error evaluating choice filter",
            description: `Please check if the syntax is correct. 
                 Select One: ${props.name} - ${props.choiceFilter}`
         });

         console.error(e, "SelectOne.jsx - evaluate");
         return [];
      }
   }, [props, relatedInputs]);
};

export default useEvaluateChoiceFilter;
