import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";

import styles from "./UserManagement.module.scss";

const PreviewMemberModal = ({
   visible,
   onCancel,
   editMemberData,
   adminUserManagementPage = false
}) => {
   const [form] = Form.useForm();

   useEffect(() => {
      if (editMemberData) {
         const rolesNames = editMemberData.roles?.map((role) =>
            role?.name?.replace(/([a-z])([A-Z])/g, "$1 $2")
         );
         const workspacesNames = editMemberData.workspaces?.map((workspace) => workspace?.name);
         const languagesNames = editMemberData.selectedLanguages?.map((language) => language?.name);

         form.setFieldsValue({
            fullName: editMemberData?.fullName,
            email: editMemberData.email,
            gender: editMemberData.gender,
            role: rolesNames, // Array of role names
            location: adminUserManagementPage
               ? editMemberData.country?.name
               : editMemberData.location,
            workspace: workspacesNames, // Array of workspace names
            language: languagesNames // Array of language names
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editMemberData]);

   return (
      <Modal
         open={visible}
         onCancel={() => onCancel("previewMemberModal")}
         width={800}
         closable={false}
      >
         <Row gutter={16} justify="center">
            <Col span={23}>
               {" "}
               <h1
                  className="modal-heading"
                  style={{
                     color: "#4158CF",
                     textAlign: "center",
                     marginLeft: "50px",
                     fontSize: "22px"
                  }}
               >
                  Preview Member
               </h1>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
               <Button type="text" onClick={() => onCancel("previewMemberModal")}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                  >
                     <path
                        d="M17.1855 1.16675L1.18555 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M1.18555 1.16675L17.1855 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </Button>
            </Col>
         </Row>
         <Form form={form} layout="vertical">
            <Row gutter={24}>
               <Col span={12}>
                  <Form.Item
                     label="Full Name"
                     name="fullName"
                     rules={[{ required: true, message: "Please enter full name" }]}
                  >
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item
                     label="Email"
                     name="email"
                     rules={[{ required: true, message: "Please enter email" }]}
                  >
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item label="Gender" name="gender">
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item
                     label="Language*"
                     name="language"
                     rules={[
                        {
                           required: true,
                           message:
                              "Kindly specify your language preference for a tailored experience."
                        }
                     ]}
                  >
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item
                     label="Location*"
                     name="location"
                     rules={[
                        {
                           required: true,
                           message:
                              "For accurate information, please provide your current location."
                        }
                     ]}
                  >
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
               {adminUserManagementPage && (
                  <Col span={12}>
                     <Form.Item
                        label="Select Workspaces*"
                        name="workspace"
                        rules={[
                           {
                              required: true,
                              message: "For organizational purposes, please indicate your workspace"
                           }
                        ]}
                     >
                        <Input disabled className={styles.customDisabledInput} />
                     </Form.Item>
                  </Col>
               )}
               <Col span={12}>
                  <Form.Item
                     label="Select Role*"
                     name="role"
                     rules={[
                        {
                           required: true,
                           message: "Specify your role or position within the organization."
                        }
                     ]}
                  >
                     <Input disabled className={styles.customDisabledInput} />
                  </Form.Item>
               </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: "20px" }}>
               <Col span={24}>
                  <div className={styles.buttonsDiv}>
                     <Button
                        onClick={() => onCancel("previewMemberModal")}
                        className={styles.buttonCancel}
                     >
                        Cancel
                     </Button>
                  </div>
               </Col>
            </Row>
         </Form>
      </Modal>
   );
};

export default PreviewMemberModal;
