import React, { forwardRef, useCallback, useState } from "react";
import { editDataStore, useTranslateStore } from "../../../surveyStore";
import { Select, Button, notification } from "antd";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import { Constants, isPublicSurveyRoute } from "../../../../../../utils/constants";
import { httpService } from "../../../../../../base/services/httpService.service";
import { useParams } from "react-router-dom";
import { SurveyStatusEnum } from "../../../../../../utils/enums";

import "./PreviewLanguage.scss";

const PreviewLanguage = forwardRef((props, ref) => {
   const { id } = useParams();
   const [loading, setLoading] = useState(false);
   const handlePrint = useReactToPrint({
      content: () => ref.current,
      documentTitle: "",
      ref: { ref }
      //  pageStyle: {pageStyle}
   });
   const { setToLanguage, toLanguage } = useTranslateStore();
   const surveyBaseLanguage = editDataStore((state) => state.surveyBaseLanguage);
   const isEditingSurvey = props.surveyAlldata?.surveyStatusId === SurveyStatusEnum.Editing;

   const handleChange = (value) => {
      if (value === toLanguage) {
         return;
      }
      setToLanguage(value);
   };

   const handleExportSurvey = useCallback(() => {
      setLoading(true);
      httpService.getFile(
         `/DataCollection/exportSurvey/${id}`,
         null,
         (response) => {
            // Create a URL for the blob
            const url = window.URL.createObjectURL(response.data);

            // Create a temporary link element to trigger the download
            const link = document.createElement("a");
            link.href = url;

            // Attempt to extract the filename from the Content-Disposition header
            const contentDisposition = response.headers["content-disposition"];
            let filename = "survey_export.xlsx";
            if (contentDisposition) {
               const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
               if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
               }
            }

            link.setAttribute("download", filename);

            // Append the link to the body, click it, and remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
            setLoading(false);
         },
         (_error) => {
            setLoading(false);
            notification.error({
               message: "Error",
               description: "Download failed. Please try again."
            });
         }
      );
   }, [id]);

   const optionList = props?.surveyAlldata?.surveyLanguageMaps
      ?.filter((lang) => lang.translateStatus === 0 || lang.isBaseLanguage)
      ?.map((lang) => ({
         value: lang.languageId,
         label: lang.name
      }));

   const shouldAddArabicStyle = toLanguage === Constants.ARABIC_LANGUAGE_ID;

   return (
      <div className="preview-language">
         <div className="preview-language--select">
            <label
               style={{
                  marginLeft: `${shouldAddArabicStyle ? "20px" : "0px"}`
               }}
            >
               Survey Language
            </label>
            <Select
               style={{
                  width: "100%",
                  display: "block"
               }}
               placeholder="Select an option"
               value={toLanguage ? toLanguage : surveyBaseLanguage}
               onChange={handleChange}
               options={optionList ?? []}
            />
         </div>
         <div className="preview-language--actions">
            <div className="preview-language--actions_print">
               <Button
                  onClick={handlePrint}
                  icon={<PrinterOutlined style={{ fontSize: "16px", color: "#5770CB" }} />}
               >
                  Print Survey
               </Button>
            </div>
            {isEditingSurvey && !isPublicSurveyRoute() ? (
               <div className="preview-language--actions_export">
                  <Button
                     onClick={handleExportSurvey}
                     loading={loading}
                     icon={<UploadOutlined style={{ fontSize: "16px" }} />}
                  >
                     {loading ? "Processing..." : "Export survey"}
                  </Button>
               </div>
            ) : null}
         </div>
      </div>
   );
});

export default React.memo(PreviewLanguage);
