import { DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { httpService } from "../../../../base/services/httpService.service";
import { LocalStorageService } from "../../../../base/services/local-storage.service";
import AuthService from "../../../../base/services/authentication.service";
import { UserRolesEnum } from "../../../auth/userRoles.enum";
import { DeleteComponent } from "../../../admin/workspace/components/DeleteComponent";
import DeletedProjectsModal from "./DeletedProjectsModal";
import { EntityTypeEnum } from "../../../../utils/enums";
import https from "../../../../base/utils/http";
import DeletedModalButton from "../../../../components/DeletedItemsComponents/DeletedModalButton/DeletedModalButton";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/withErrorBoundary";

import "./index.scss";

const PAGE_SIZE = 10;

function ProjectSelection() {
   const [searchValue, setSearchValue] = useState({ searchInput: "", selectedOption: null });
   const [project, setProject] = useState([]);
   const navigate = useNavigate();
   const [deleteModal, setDeleteModal] = useState("");
   const [reload, setReload] = useState(false);
   const [tableLoading, setTableLoading] = useState(false);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: PAGE_SIZE,
      total: 0
   });

   const [openDeletedModal, setOpenDeletedModal] = useState(false);

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         handleButtonClick();
      }
   };
   const handleInputChange = (e) => {
      const inputValue = e.target.value;
      setSearchValue({ ...searchValue, searchInput: inputValue });
      if (!inputValue) {
         getProjectsInWorkspace(1, 10, true);
      }
   };

   const rowClickHandler = (record) => {
      onProjectClick(record);
   };

   const handleButtonClick = () => {
      getProjectsInWorkspace(pagination.current, pagination.pageSize);
   };

   const onProjectClick = (record) => {
      LocalStorageService.setItem("currentProjectID", record.key);
      navigate("/dashboard");
   };

   const columns = [
      {
         title: "Project Name",
         dataIndex: "title",
         key: "title",
         render: (text, record) => (
            <div
               className="project-cell"
               style={{ height: "120%" }}
               onClick={() => onProjectClick(record)}
            >
               {text}
            </div>
         )
      },
      {
         title: "Manager",
         dataIndex: "manager",
         key: "manager",
         render: (text, record) => (
            <div className="project-cell" onClick={() => onProjectClick(record)}>
               {text}
            </div>
         )
      },
      {
         title: "Linked Surveys",
         dataIndex: "linkedSurvey",
         key: "linkedSurvey",
         render: (text, record) => (
            <div className="project-cell" onClick={() => onProjectClick(record)}>
               {text}
            </div>
         )
      },
      {
         title: "Members",
         dataIndex: "members",
         key: "members",
         render: (text, record) => (
            <div className="project-cell" onClick={() => onProjectClick(record)}>
               {text}
            </div>
         )
      },
      {
         title: <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>,
         render: (record) => (
            <div className="d-flex" style={{ gap: "10px" }}>
               {AuthService.isAuthorized([
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Organization,
                  UserRolesEnum.AdminOfOrganization,
                  UserRolesEnum.SeniorProjectManager,
                  UserRolesEnum.ProjectManager
               ]) && (
                  <ActionButton onClick={() => setDeleteModal(record.key)}>
                     <DeleteOutlined style={{ fontWeight: "600" }} />
                  </ActionButton>
               )}
               <ActionButton onClick={() => onProjectClick(record)}>
                  <ArrowRightOutlined style={{ fontWeight: "600" }} />
               </ActionButton>
            </div>
         )
      }
   ];

   const getProjectsInWorkspace = (page, pageSize, bool) => {
      setTableLoading(true);

      const workspcId = LocalStorageService?.getItem("currentWorkspaceId") || "";
      const searchInput = bool ? "" : searchValue.searchInput;

      httpService.get(
         `/Projects/getMyProjectsInWorkspace/${workspcId}?page=${page}&pageSize=${pageSize}&search=${searchInput}&isDeleted=false`,
         (res) => {
            if (res.isSuccess) {
               const filteredProjects = res.data.projects.map((project) => ({
                  key: project.id,
                  title: <>{project.name}</>,
                  manager: project.manager.fullName,
                  description: project.description,
                  type: project.name,
                  linkedSurvey: project.countOfSurveys,
                  members: project.projectMembers,
                  importedBeneficiaryTableIds: project.importedBeneficiaryTableIds
               }));

               setProject(filteredProjects);

               setPagination({
                  ...pagination,
                  total: res.data.totalSize,
                  current:
                     res.data?.projects?.length === 0 && res.data?.totalSize !== 0
                        ? pagination.current - 1
                        : pagination.current,
                  pageSize: pageSize
               });
            }
            setTableLoading(false);
         },
         (err) => {
            notification.error({ message: "Error fetching projects, please try again later." });
            setTableLoading(false);
         }
      );
   };

   useEffect(() => {
      getProjectsInWorkspace(pagination.current, pagination.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reload]);

   const handleDeleteSurvey = async (id) => {
      try {
         const params = new URLSearchParams({
            entityType: EntityTypeEnum.Project,
            isDeleted: true
         });
         const res = await https.put(
            `/DataCollection/${id}/toggleDeleteOrRestore?${params.toString()}`
         );

         notification.open({
            type: res.data.isSuccess ? "success" : "error",
            message: (
               <div>
                  <div>{res.data.message || "Something went wrong! Please try again later."}</div>
                  <br />
                  <ul>
                     {res?.data?.errors?.map((err) => (
                        <li key={err}>{err}</li>
                     ))}
                  </ul>
               </div>
            )
         });
         if (res.data.isSuccess) {
            setReload((prev) => !prev);
            setDeleteModal("");
         }
      } catch (err) {
         notification.error({
            message: (
               <div>
                  <div>
                     {err.response?.data?.message ||
                        "Error deleting project, please try again later."}
                  </div>

                  <ul>
                     {err.response?.data?.errors?.map((err) => (
                        <li key={err}>{err}</li>
                     ))}
                  </ul>
               </div>
            )
         });
      }
   };

   // TODO: remove typeId because it doesn't have any use
   const typeId = "31177118-62ad-4650-a8de-fc23644aacf3";

   return (
      <>
         <div className="projects-selection-page">
            <div className="container">
               <div className="row">
                  <div className="col">
                     <h1 className="heading">Project List</h1>
                  </div>
                  <div className="col">
                     <div className="create-button w-100">
                        {AuthService.isAuthorized([
                           UserRolesEnum.SuperAdmin,
                           UserRolesEnum.Organization,
                           UserRolesEnum.AdminOfOrganization,
                           UserRolesEnum.SeniorProjectManager,
                           UserRolesEnum.ProjectManager
                        ]) && (
                           <Link to={"/project-selection/create-project/" + typeId}>
                              <Button className="mb-2 new-projects-button">
                                 <span>
                                    <PlusOutlined /> Create new project{" "}
                                 </span>
                              </Button>
                           </Link>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            <div className="line"></div>
            <div className="project-selection_table-actions">
               <div className="project-selection_table-actions--filters">
                  <Input
                     value={searchValue.searchInput}
                     onChange={handleInputChange}
                     onKeyPress={handleKeyPress}
                     prefix={<SearchOutlined />}
                     placeholder="Search by Project Name"
                     className="search-input"
                  />

                  {/* TODO: add filter by status later */}
                  {/* <Input
                     value={searchValue.searchInput}
                     onChange={handleInputChange}
                     onKeyPress={handleKeyPress}
                     prefix={<SearchOutlined />}
                     placeholder="--Filter by status--"
                     className="filter-input"
                  /> */}
                  <Button size="large" onClick={handleButtonClick} className="search-button">
                     Search
                  </Button>
               </div>
               <div className="project-selection_table-actions--extra">
                  <DeletedModalButton
                     allowedRoles={[
                        UserRolesEnum.SuperAdmin,
                        UserRolesEnum.Organization,
                        UserRolesEnum.AdminOfOrganization,
                        UserRolesEnum.SeniorProjectManager,
                        UserRolesEnum.ProjectManager
                     ]}
                     onClick={() => setOpenDeletedModal(true)}
                  >
                     Deleted projects
                  </DeletedModalButton>
               </div>
            </div>
            <Table
               style={{ overflowX: "auto" }}
               dataSource={project}
               columns={columns}
               pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                     setPagination({ ...pagination, current: page, pageSize });
                     getProjectsInWorkspace(page, pageSize);
                  }
               }}
               loading={tableLoading}
               onRow={(record) => ({
                  onClick: (event) => {
                     if (!event.target.closest(".ant-table-cell:last-child")) {
                        rowClickHandler(record);
                     }
                  }
               })}
            />
         </div>
         {deleteModal !== "" && (
            <DeleteComponent
               open={deleteModal.length > 0}
               setOpen={() => setDeleteModal("")}
               showModal={() => setDeleteModal("")}
               handleDelete={handleDeleteSurvey}
               id={deleteModal}
            />
         )}
         <DeletedProjectsModal
            isModalOpen={openDeletedModal}
            setIsModalOpen={setOpenDeletedModal}
            handleOk={() => setReload((prev) => !prev)}
            handleCancel={() => setReload((prev) => !prev)}
         />
      </>
   );
}

export default withErrorBoundary(ProjectSelection);

const ActionButton = ({ children, onClick }) => {
   return (
      <Button
         type="text"
         style={{
            width: "32px",
            height: "32px",
            color: "white",
            backgroundColor: "#5770CB"
         }}
         onClick={onClick}
      >
         <Flex justify="center" align="center">
            {children}
         </Flex>
      </Button>
   );
};
