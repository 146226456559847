import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastMessages = {
   success: (message) => {
      toast.success(message, {
         toastId: Math.random(),
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined
      });
   },
   error: (message) => {
      toast.error(message, {
         toastId: Math.random(),
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined
      });
   },
   warning: (message) => {
      toast.warning(message, {
         toastId: Math.random(),
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined
      });
   }
   // error
   // warning
   // info
   // default
   // dismiss
};

export default toastMessages;
