import React, { useCallback, useEffect, useMemo } from "react";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "./Date.scss";

dayjs.extend(customParseFormat);

export const Date = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers, name } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = hasSubmissionId
         ? answers?.find((answer) => answer?.submissionId === submissionId)?.answerDetail?.text
         : null;

      const value =
         hasSubmissionId && submissionAnswer
            ? dayjs(submissionAnswer, "YYYY-MM:DD")
            : inputState
            ? dayjs(inputState?.answerDetail?.text, "YYYY-MM:DD")
            : "";

      const passConstraint = useEvaluateConstraint(props.constraint, inputState?.skipLogicAnswer);

      const showErrors =
         hasErrors && isRequired && (!inputState || !inputState?.answerDetail?.text);

      const onDatePickerChange = useCallback(
         (date, dataString) => {
            if (hasSubmissionId) {
               return;
            }
            if (date) {
               setInputs({
                  target: {
                     name: id,
                     value: {
                        surveyId: surveyId,
                        userId: userId,
                        questionId: id,
                        questionType: questionType,
                        name: name,
                        skipLogicAnswer: date.startOf("day"),
                        answerDetail: {
                           text: dataString
                        }
                     }
                  }
               });
            } else {
               setInputs({ target: { name: id, value: "" } });
            }
         },
         [hasSubmissionId, id, name, questionType, setInputs, surveyId, userId]
      );

      const memoDatePicker = useMemo(() => {
         return (
            <DatePicker
               onChange={onDatePickerChange}
               placeholder="YYYY-MM-DD"
               inputReadOnly={hasSubmissionId}
               {...(hasSubmissionId && { open: false })}
               status={showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""}
               value={value}
               size="large"
               prefixCls="date-submission-type-wrapper--date-picker"
            />
         );
      }, [hasSubmissionId, onDatePickerChange, passConstraint, showErrors, value]);

      useEffect(() => {
         if (defaultValue) {
            const defaultDate = defaultValue?.$isDayjsObject ? defaultValue : dayjs(props.default);

            onDatePickerChange(defaultDate, defaultDate.format("YYYY-MM-DD"));
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue, props.default]);

      return (
         <div className="date-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            {memoDatePicker}

            <span className="date-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
