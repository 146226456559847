import React, { useCallback, useEffect, useState } from "react";
import { Button, notification, Table, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FiUsers } from "react-icons/fi";
import { NewMember } from "../../pages/admin/user-management/NewMember";
import EditMemberModal from "../../pages/admin/user-management/EditMemberModal";
import PreviewMemberModal from "../../pages/admin/user-management/PreviewMemberModal";
import { DeleteComponent } from "../../pages/admin/workspace/components/DeleteComponent";
import MembersFilters from "./MembersFilters";
import SearchFilter from "../../ui/SearchFilter/SearchFilter";
import { httpService } from "../../base/services/httpService.service";
import { getOrderedRoles } from "../../pages/admin/helpers";
import { createQueryParams } from "../../helpers/queryParams";
import AuthService from "../../base/services/authentication.service";
import { LocalStorageService } from "../../base/services/local-storage.service";
import https from "../../base/utils/http";
import { EntityTypeEnum, UserRolesEnum } from "../../utils/enums";
import icon1 from "../../assets/images/previewUserManagement.png";
import icon2 from "../../assets/images/editUserManagement.png";
import icon3 from "../../assets/images/deleteUserManagement.png";

import styles from "../../pages/admin/user-management/UserManagement.module.scss";

const PAGE_SIZE = 10;

const MembersView = React.memo(
   ({ workspaces, columns, projects, adminUserManagementPage = false }) => {
      const [loading, setLoading] = useState(false);
      const [visible, setVisible] = useState(false);
      const [rolesList, setRolesList] = useState([]);
      const [members, setMembers] = useState([]);
      const [countries, setCountries] = useState([]);
      const [languages, setLanguages] = useState([]);
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const [deleteItemId, setDeleteItemId] = useState(null);
      const [showFilters, setShowFilters] = useState(false);
      const [editModalVisible, setEditModalVisible] = useState(false);
      const [previewModalVisible, setPreviewModalVisible] = useState(false);
      const [editMemberData, setEditMemberData] = useState(null);
      const [pagination, setPagination] = useState({
         current: 1,
         pageSize: PAGE_SIZE,
         total: 0
      });
      const [filterValue, setFilterValue] = useState({
         searchInput: "",
         roles: [],
         languages: [],
         genderValues: [],
         locations: [],
         workspaces: [],
         projects: []
      });

      const handleOpenModal = () => {
         setVisible(true);
      };

      const handleCloseModal = (modal) => {
         if (modal === "newMemberModal") {
            setVisible(false);
            return;
         }
         if (modal === "editMemberModal") {
            setEditModalVisible(false);
            return;
         }
         if (modal === "previewMemberModal") {
            setPreviewModalVisible(false);
            return;
         }
      };

      const getRoleIdAndName = (roleName) => {
         const simplifiedRoleMap = {
            SeniorProjectManager: "SeniorManager",
            JuniorProjectManager: "JuniorManager"
         };
         const simplifiedRoleName = simplifiedRoleMap[roleName] || roleName;
         const roleId = UserRolesEnum[simplifiedRoleName] || null;

         return { roleId, roleName: simplifiedRoleName };
      };

      const getAllProjectMembers = useCallback(
         async (page, pageSize) => {
            setLoading(true);
            const queryParams = createQueryParams(filterValue);
            const projectID = LocalStorageService.getItem("currentProjectID");

            const params = new URLSearchParams({
               isDeleted: false,
               Page: page,
               PageSize: pageSize,
               ProjectId: projectID
            });
            const additionalParams = new URLSearchParams(queryParams);
            for (const [key, value] of additionalParams) {
               params.append(key, value);
            }

            httpService.get(
               `/Projects/getAllProjectMembers/${projectID}?${params.toString()}`,
               (response) => {
                  const data = {
                     ...response.data,
                     members: response.data.members.map((member) => {
                        const { roleId, roleName } = getRoleIdAndName(member.role);
                        return {
                           key: member.memberId,
                           id: member.memberId,
                           fullName: member?.name,
                           gender: member?.gender,
                           email: member?.email,
                           organizationMemberId: member?.organizationMemberId,
                           projectMemberId: member?.projectMemberId,
                           selectedLanguages: member?.selectedLanguages.map((language) => ({
                              id: language.id,
                              name: language.name
                           })),
                           location: member?.location,
                           roles: [{ id: roleId, name: roleName }]
                        };
                     })
                  };
                  setMembers(data?.members);
                  setLoading(false);
                  setPagination({
                     ...pagination,
                     total: response.data.totalSize,
                     current: page,
                     pageSize: pageSize
                  });
               },
               (_err) => {
                  setLoading(false);
               }
            );
         },
         // eslint-disable-next-line react-hooks/exhaustive-deps
         [filterValue]
      );

      const getAllMembersOfOrganization = useCallback(
         async (page, pageSize) => {
            setLoading(true);
            const queryParams = createQueryParams(filterValue);
            await httpService.get(
               `/Members/getAllMembersOfOrganization?${queryParams}&page=${page}&pageSize=${pageSize}`,
               (res) => {
                  const allMembers = res.data?.members?.map((u) => ({ ...u, key: u.id }));
                  setMembers(allMembers);
                  setLoading(false);
                  setPagination((prevState) => ({
                     ...prevState,
                     current: page,
                     pageSize: pageSize,
                     total: res.data.totalSize
                  }));
               },
               (_error) => {
                  setLoading(false);
               }
            );
         },
         [filterValue]
      );

      const getAllOrganizationRoles = useCallback(() => {
         httpService.get("/Roles/getAllOrganizationRoles", (res) => {
            if (!res.data) return;
            const roles = getOrderedRoles(
               res.data?.filter(
                  (item) => item.name !== "Organization" && item.name !== "OrgSuperAdmin"
               )
            );
            setRolesList(roles);
         });
      }, []);

      const getAllCountries = useCallback(() => {
         httpService.get(`/Languages/getAllCountries`, (res) => {
            if (!res.data) return;
            setCountries(
               res.data.map((country) => ({
                  value: country.id,
                  label: country.name
               }))
            );
         });
      }, []);

      const getAllLanguages = useCallback(() => {
         httpService.get(`Languages/getAll`, (res) => {
            if (!res.data) return;
            setLanguages(
               res.data.map((lang) => ({
                  value: lang.id,
                  label: lang.name
               }))
            );
         });
      }, []);

      useEffect(() => {
         if (filterValue.searchInput.trim() === "") {
            if (adminUserManagementPage) {
               getAllMembersOfOrganization(pagination.current, pagination.pageSize);
            } else {
               getAllProjectMembers(pagination.current, pagination.pageSize);
            }
            return;
         }

         const timeoutId = setTimeout(() => {
            if (adminUserManagementPage) {
               getAllMembersOfOrganization(pagination.current, pagination.pageSize);
            } else {
               getAllProjectMembers(pagination.current, pagination.pageSize);
            }
         }, 500);

         return () => clearTimeout(timeoutId);
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filterValue, adminUserManagementPage]);

      useEffect(() => {
         getAllOrganizationRoles();
         getAllCountries();
         getAllLanguages();
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handlePagination = useCallback(
         (page, pageSize) => {
            adminUserManagementPage
               ? getAllMembersOfOrganization(page, pageSize)
               : getAllProjectMembers(page, pageSize);
            setPagination((prevS) => ({ ...prevS, current: page, pageSize }));
         },
         // eslint-disable-next-line react-hooks/exhaustive-deps
         [pagination]
      );

      const handleSearch = useCallback(
         (e) => {
            setPagination((prevS) => ({ ...prevS, current: 1 }));
            setFilterValue((prevS) => ({ ...prevS, searchInput: e.target.value }));
         },
         [setPagination, setFilterValue]
      );

      const handleClearFilters = useCallback(() => {
         setFilterValue((prevFilterValue) => ({
            ...prevFilterValue,
            searchInput: "",
            roles: [],
            languages: [],
            locations: [],
            genderValues: [],
            workspaces: [],
            projects: []
         }));
         setPagination((prevS) => ({ ...prevS, current: 1 }));
      }, []);

      const handleShowFilters = useCallback(() => {
         setShowFilters((prevState) => !prevState);
      }, []);

      const onSelect = useCallback((value, key) => {
         setFilterValue((prevS) => ({ ...prevS, [key]: value }));
         setPagination((prevS) => ({ ...prevS, current: 1 }));
      }, []);

      const openDeleteModal = (record) => {
         setDeleteItemId(record);
         setDeleteModalOpen(true);
      };

      const closeDeleteModal = () => {
         setDeleteModalOpen(false);
         setDeleteItemId(null);
      };

      const additionalColumn = {
         title: "",
         key: "action",
         render: (_, record) => (
            <div>
               <img
                  src={icon1}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePreview(record)}
                  alt="Preview user icon"
               />
               <img
                  src={icon2}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(record)}
                  alt="Edit user icon"
               />
               <Tooltip
                  title={
                     adminUserManagementPage
                        ? ""
                        : "Deleting a member here will soft delete them from this project only. To delete them from the organization, go to the User Management page."
                  }
                  placement="bottom"
               >
                  <img
                     src={icon3}
                     style={{ cursor: "pointer" }}
                     onClick={() => openDeleteModal(record)}
                     alt="Delete user icon"
                  />
               </Tooltip>
            </div>
         ),
         fixed: "right",
         width: 100
      };

      const membersColumns = AuthService.isAuthorized(
         adminUserManagementPage
            ? [UserRolesEnum.Organization, UserRolesEnum.SeniorProjectManager]
            : [
                 UserRolesEnum.SuperAdmin,
                 UserRolesEnum.Organization,
                 UserRolesEnum.AdminOfOrganization,
                 UserRolesEnum.SeniorProjectManager,
                 UserRolesEnum.ProjectManager
              ]
      )
         ? columns.concat(additionalColumn)
         : columns;

      const handleDelete = async (record) => {
         try {
            const params = new URLSearchParams({
               entityType: adminUserManagementPage
                  ? EntityTypeEnum.OrganizationMember
                  : EntityTypeEnum.ProjectMember,
               isDeleted: true
            });
            const res = await https.put(
               `/DataCollection/${
                  adminUserManagementPage ? record.organizationMemberId : record.projectMemberId
               }/toggleDeleteOrRestore?${params.toString()}`
            );

            notification.open({
               type: res.data.isSuccess ? "success" : "error",
               message: res.data.message || "Something went wrong! Please try again later."
            });
            if (res.data.isSuccess) {
               if (adminUserManagementPage) {
                  getAllMembersOfOrganization(1, 10);
               } else {
                  getAllProjectMembers(1, 10);
               }
            }
         } catch (_err) {
            notification.error({ message: "Error deleting user, please try again later." });
         }
      };

      const handleEdit = (data) => {
         setEditMemberData(data); // Set data of the member being edited
         setEditModalVisible(true); // Open the Edit modal
      };
      const handlePreview = (data) => {
         setEditMemberData(data); // Set data of the member being edited
         setPreviewModalVisible(true); // Open the Edit modal
      };

      return (
         <div className="admin-members project-members">
            <div className={styles.firstRowWrapper}>
               {adminUserManagementPage ? (
                  <h3>Workspace members</h3>
               ) : (
                  <h3>
                     User Management
                     <span>
                        <FiUsers />
                        {pagination.total}
                     </span>
                  </h3>
               )}
               {(adminUserManagementPage
                  ? AuthService.isAuthorized([UserRolesEnum.Organization])
                  : AuthService.isAuthorized([
                       UserRolesEnum.SuperAdmin,
                       UserRolesEnum.Organization,
                       UserRolesEnum.AdminOfOrganization,
                       UserRolesEnum.SeniorProjectManager,
                       UserRolesEnum.ProjectManager
                    ])) && (
                  <Button
                     className="kapSurveyPrimaryButton"
                     icon={<PlusOutlined />}
                     disabled={loading}
                     loading={loading}
                     onClick={handleOpenModal}
                  >
                     {loading ? <>Processing</> : "Add member"}
                  </Button>
               )}
               <NewMember
                  visible={visible}
                  onCancel={handleCloseModal}
                  rolesList={rolesList.filter((item) =>
                     adminUserManagementPage
                        ? item.name === "Senior Manager" ||
                          item.name === "Project Manager" ||
                          item.name === "Guest"
                        : AuthService.isAuthorized([UserRolesEnum.SeniorProjectManager])
                        ? item.name === "Data Collector" ||
                          item.name === "Junior Manager" ||
                          item.name === "Project Manager"
                        : item.name === "Data Collector" || item.name === "Junior Manager"
                  )}
                  fetchTableData={() =>
                     adminUserManagementPage
                        ? getAllMembersOfOrganization(1, 10)
                        : getAllProjectMembers(1, 10)
                  }
                  languages={languages}
                  countries={countries}
                  workspaces={workspaces}
                  adminUserManagementPage={adminUserManagementPage}
               />
            </div>
            <SearchFilter
               showFilters={showFilters}
               handleSearch={handleSearch}
               handleShowFilters={handleShowFilters}
               handleClearFilters={handleClearFilters}
            >
               <MembersFilters
                  onSelectChange={onSelect}
                  filterValue={filterValue}
                  rolesList={rolesList}
                  languages={languages}
                  countries={countries}
                  workspaces={workspaces}
                  projects={projects}
                  adminUserManagementPage={adminUserManagementPage}
               />
            </SearchFilter>

            <Table
               columns={membersColumns}
               dataSource={members}
               loading={loading}
               pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                     handlePagination(page, pageSize);
                  }
               }}
            />
            <DeleteComponent
               open={deleteModalOpen}
               setOpen={setDeleteModalOpen}
               showModal={deleteModalOpen}
               handleDelete={(record) => {
                  handleDelete(record);
                  closeDeleteModal();
               }}
               id={deleteItemId}
            />
            {editModalVisible && (
               <EditMemberModal
                  visible={editModalVisible}
                  onCancel={handleCloseModal}
                  editMemberData={editMemberData}
                  rolesList={rolesList}
                  languages={languages}
                  countries={countries}
                  workspaces={workspaces}
                  adminUserManagementPage={adminUserManagementPage}
                  fetchTableData={() =>
                     adminUserManagementPage
                        ? getAllMembersOfOrganization(pagination.current, pagination.pageSize)
                        : getAllProjectMembers(pagination.current, pagination.pageSize)
                  }
               />
            )}
            {previewModalVisible && (
               <PreviewMemberModal
                  visible={previewModalVisible}
                  onCancel={handleCloseModal}
                  editMemberData={editMemberData}
                  adminUserManagementPage={adminUserManagementPage}
               />
            )}
         </div>
      );
   }
);

export default MembersView;
