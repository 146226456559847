import React, { useState } from "react";
import { httpService } from "../../../base/services/httpService.service";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Col, Select, Row, notification, Modal, Tabs, Upload } from "antd";
import {
   getLanguageIdByName,
   getLocationIdByName,
   getRoleIdByName,
   getWorkspaceIdByName
} from "../helpers";
import icon from "../../../assets/images/uploadDraggerIcon.png";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import {
   CountriesEnum,
   ExcelUserRolesEnum,
   LanguagesEnum,
   UserRolesEnum
} from "../../../utils/enums";
import { genderOptions } from "../../../utils/options";
import { read, utils } from "xlsx";
import styles from "./UserManagement.module.scss";

const { Option } = Select;
const { Dragger } = Upload;

function NewMember({
   rolesList,
   languages,
   countries,
   workspaces,
   record,
   visible,
   onCancel,
   adminUserManagementPage = false,
   isEnumeratorsPage = false,
   fetchTableData = () => {}
}) {
   const [form] = Form.useForm();
   const navigate = useNavigate();
   const [load, setLoad] = useState(false);
   const [fileList, setFileList] = useState([]);
   const [membersData, setMembersData] = useState([]);

   const initialValues = {
      username: record?.fullName,
      email: record?.email,
      language: record?.languages,
      gender: record?.gender,
      location: record?.location,
      role: record?.role,
      workspace: record?.workspaceName
   };

   const createOrUpdateMember = (api, values) => {
      const editApi = api === "edit";
      form.setFieldsValue(values);

      httpService.post(
         `/Members/${editApi ? "edit" : "add"}`,
         {
            fullName: values.username,
            email: values.email,
            roleId: isEnumeratorsPage
               ? UserRolesEnum.DataCollector
               : getRoleIdByName(rolesList, values),
            workspaceIds:
               isEnumeratorsPage || !adminUserManagementPage
                  ? [LocalStorageService.getItem("currentWorkspaceId")]
                  : getWorkspaceIdByName(workspaces, values),
            ...(isEnumeratorsPage || !adminUserManagementPage
               ? { projectId: LocalStorageService.getItem("currentProjectID") }
               : {}),
            languages: getLanguageIdByName(languages, values),
            locationId: getLocationIdByName(countries, values),
            gender: values.gender,
            id: editApi ? record.key : undefined
         },
         (res) => {
            if (res?.isSuccess) {
               notification.open({
                  type: "success",
                  message: `${
                     isEnumeratorsPage ? "Enumerator" : "Member"
                  }  has been added successfully.`
               });
               fetchTableData();
               if (!isEnumeratorsPage && adminUserManagementPage)
                  navigate("/admin-user-management");
               setLoad(false);
               onCancel("newMemberModal");
               form.resetFields();
            }
         },
         (error) => {
            setLoad(false);
            form.setFieldsValue(values);
            if (error.response && error.response.data && error.response.data.errors) {
               const errorMessage = error.response.data.errors[0];
               notification.error({
                  type: "error",
                  message: errorMessage
               });
            } else {
               notification.error({
                  type: "error",
                  message: `Something went wrong`
               });
            }
         }
      );
   };

   const onFinish = (values) => {
      setLoad(true);
      if (!record) {
         createOrUpdateMember("add", values);
      } else {
         createOrUpdateMember("edit", values);
      }
   };

   const handleFileUpload = ({ file }) => {
      const reader = new FileReader();
      reader.onload = (e) => {
         const data = new Uint8Array(e.target.result);
         const workbook = read(data, { type: "array" });

         // Process sheets
         const membersSheet = workbook.Sheets["Members"];
         const rolesSheet = workbook.Sheets["Roles"];

         const membersData = utils.sheet_to_json(membersSheet);
         const rolesData = utils.sheet_to_json(rolesSheet);

         // Extract valid roles
         const validRoles = rolesData.map((role) => role.Role);

         const roleIdMapping = ExcelUserRolesEnum;

         const locationIdMapping = CountriesEnum;

         const languagesMapping = LanguagesEnum;

         const validLanguagesIds = Object.values(languagesMapping);
         const validCountries = Object.keys(locationIdMapping);

         const validatedMembers = membersData
            .map((member) => {
               if (!validRoles.includes(member.Role)) {
                  console.log(`Invalid role for member: ${member["FullName"]}`);
                  return null;
               }

               const memberLanguagesIds = member.Languages.split(",").map(
                  (lang) => languagesMapping[lang.trim().toUpperCase()]
               );

               const invalidLangIds = memberLanguagesIds.filter(
                  (langId) => !validLanguagesIds.includes(langId)
               );
               if (invalidLangIds.length > 0) {
                  console.log(
                     `Invalid language(s) for member: ${member["FullName"]}: ${invalidLangIds.join(
                        ", "
                     )}`
                  );
                  return null;
               }

               if (!validCountries.includes(member.Location.toUpperCase())) {
                  console.log(`Invalid location for member: ${member["FullName"]}`);
                  return null;
               }

               return {
                  FullName: member["FullName"],
                  Email: member.Email,
                  RoleId: roleIdMapping[member.Role],
                  WorkspaceIds: [LocalStorageService.getItem("currentWorkspaceId")],
                  ProjectId: LocalStorageService.getItem("currentProjectID"),
                  Languages: memberLanguagesIds,
                  LocationId: locationIdMapping[member.Location.toUpperCase()],
                  Gender: member.Gender === "Male" ? 0 : 1
               };
            })
            .filter((member) => member !== null);

         setMembersData(validatedMembers);
      };

      reader.readAsArrayBuffer(file);
   };

   const handleCreateMultipleMembers = () => {
      setLoad(true);
      const payload = membersData.map((member) => ({
         FullName: member.FullName,
         Email: member.Email,
         RoleId: member.RoleId,
         WorkspaceIds: member.WorkspaceIds,
         ProjectId: member.ProjectId,
         Languages: member.Languages,
         LocationId: member.LocationId,
         Gender: member.Gender
      }));

      httpService.post(
         "/Members/addMembers",
         payload,
         (res) => {
            if (res?.isSuccess) {
               notification.open({
                  type: "success",
                  message: "Members have been added successfully."
               });
               fetchTableData();
               onCancel("newMemberModal");
               form.resetFields();
            }
            setLoad(false);
         },
         (error) => {
            setLoad(false);
            if (error.response && error.response.data && error.response.data.errors) {
               const errorMessage = error.response.data.errors[0];
               notification.error({
                  type: "error",
                  message: errorMessage
               });
            } else {
               notification.error({
                  type: "error",
                  message: `Something went wrong`
               });
            }
         }
      );
   };

   const items = [
      {
         key: "1",
         label: "Add one member",
         children: (
            <Form
               form={form}
               labelCol={{ span: 24 }}
               wrapperCol={{ span: 24 }}
               name="basic"
               onFinish={onFinish}
            >
               {/* First Row */}
               <h3 className={styles.newMemberHeader}>Personal information</h3>
               <Row gutter={24}>
                  <Col span={12}>
                     <Form.Item
                        label="Full name*"
                        name="username"
                        rules={[
                           {
                              required: true,
                              message: "For our records, please input your full legal name"
                           }
                        ]}
                     >
                        <Input autoComplete="off" placeholder="Enter a name" />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label="Email*"
                        name="email"
                        rules={[
                           {
                              required: true,
                              message:
                                 "We require your email address for correspondence. Please enter it below"
                           },
                           {
                              type: "email",
                              message:
                                 "For proper correspondence, enter a valid and active email address."
                           }
                        ]}
                     >
                        <Input autoComplete="off" placeholder="Enter an email" />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label="Language*"
                        name="language"
                        rules={[
                           {
                              required: true,
                              message:
                                 "Kindly specify your language preference for a tailored experience."
                           }
                        ]}
                     >
                        <Select
                           mode="multiple"
                           showSearch
                           allowClear
                           placeholder="--Select an option--"
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              option.children.toLowerCase().startsWith(input.toLowerCase())
                           }
                           style={{ width: "100%" }}
                        >
                           {languages.map((option, i) => (
                              <Option value={option.label} key={i}>
                                 {option.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label="Gender*"
                        name="gender"
                        rules={[
                           {
                              required: true,
                              message: "For demographic analysis, please specify your gender."
                           }
                        ]}
                     >
                        <Select
                           showSearch
                           placeholder="--Select a Gender--"
                           allowClear
                           options={genderOptions}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label="Location*"
                        name="location"
                        rules={[
                           {
                              required: true,
                              message:
                                 "For accurate information, please provide your current location."
                           }
                        ]}
                     >
                        <Select
                           showSearch
                           placeholder="--Select a Location--"
                           allowClear
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              option.children.toLowerCase().startsWith(input.toLowerCase())
                           }
                           style={{ width: "100%" }}
                        >
                           {countries.map((option, i) => (
                              <Option value={option.label} key={i}>
                                 {option.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </Col>
                  {!isEnumeratorsPage && adminUserManagementPage && (
                     <Col span={12}>
                        <Form.Item
                           label="Select Workspaces*"
                           name="workspace"
                           rules={[
                              {
                                 required: true,
                                 message:
                                    "For organizational purposes, please indicate your workspace"
                              }
                           ]}
                        >
                           <Select
                              placeholder="--Select Workspaces--"
                              mode="multiple"
                              allowClear
                              showSearch
                              // value={initialValues.workspace}
                           >
                              {workspaces.map((option, i) => {
                                 return (
                                    <Option value={option.name} key={i}>
                                       {option.name}{" "}
                                    </Option>
                                 );
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                  )}
                  <Col span={12}>
                     <Form.Item
                        label="Select Role*"
                        name="role"
                        rules={[
                           {
                              required: isEnumeratorsPage ? false : true,
                              message: "Specify your role or position within the organization."
                           }
                        ]}
                     >
                        <Select
                           placeholder="--Select a Role--"
                           showSearch
                           defaultValue={isEnumeratorsPage ? "Data Collector" : null}
                           disabled={isEnumeratorsPage}
                        >
                           {/* {rolesList
                                 .filter((option) => option.name !== "Org Super Admin")
                                 .map((option, i) => (
                                    <Option value={option.name} key={i}>
                                       {option.name}
                                    </Option>
                                 ))} */}
                           {rolesList?.map((option, i) => (
                              <Option value={option.name} key={i}>
                                 {option.name}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col span={24}>
                     <div className={styles.buttonsDiv}>
                        <Button
                           onClick={() => onCancel("newMemberModal")}
                           className={styles.buttonCancel}
                        >
                           Cancel
                        </Button>

                        <Form.Item>
                           <Button
                              htmlType="submit"
                              className={styles.buttonsCreateProfile}
                              disabled={load}
                              loading={load}
                           >
                              {load ? <>Processing</> : !record ? "Add New Member" : "Edit Member"}
                           </Button>
                        </Form.Item>
                     </div>
                  </Col>
               </Row>
            </Form>
         )
      },
      {
         key: "2",
         label: "Add multiple members",
         disabled: false,
         children: (
            <div>
               {/* Content of Tab 2 (addMultiple) */}
               <div className={styles.addMultipleDiv}>
                  <div className={styles.addMultipleText}>
                     To add multiple members at once, fill out this format and upload it into the
                     box below
                  </div>
                  <Button className={styles.addMultipleButton}>
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                     >
                        <path
                           d="M9.28578 10.0578L7.00007 12.3435M7.00007 12.3435L4.71436 10.0578M7.00007 12.3435V6.05776M13.2858 15.2006C13.2858 15.5037 13.1653 15.7944 12.951 16.0087C12.7368 16.223 12.446 16.3435 12.1429 16.3435H1.85721C1.5541 16.3435 1.26342 16.223 1.04909 16.0087C0.834767 15.7944 0.714355 15.5037 0.714355 15.2006V2.62919C0.714355 2.32608 0.834767 2.03539 1.04909 1.82106C1.26342 1.60674 1.5541 1.48633 1.85721 1.48633H9.28578L13.2858 5.48633V15.2006Z"
                           stroke="white"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                     <a download={"Multiple_Members"} href={"/files/Multiple_Members.xlsx"}>
                        Download Format
                     </a>
                  </Button>
               </div>
               {/* Add your form for uploading Excel file here */}
               <Dragger
                  style={{ background: "#F1F6FF", borderColor: "#B3BCEC" }}
                  accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  beforeUpload={(file) => {
                     setFileList([file]);
                     handleFileUpload({ file });
                     return false;
                  }}
                  fileList={fileList}
                  onRemove={() => setFileList([])}
               >
                  <p className="ant-upload-drag-icon">
                     <center>
                        {" "}
                        <div className={styles.uploadDraggerIcon}>
                           <img src={icon}></img>
                        </div>
                     </center>
                  </p>
                  <p className={styles.uploadDraggerHeader}>
                     Drag and drop files here, or click to select files
                  </p>
                  <p className={styles.uploadDraggerText}>
                     Excel files only <br></br> Max file size: 1 MB
                  </p>
               </Dragger>
               <Row gutter={24} style={{ marginTop: "20px" }}>
                  <Col span={24}>
                     <div className={styles.buttonsDiv}>
                        <Button
                           onClick={() => onCancel("newMemberModal")}
                           className={styles.buttonCancel}
                        >
                           Cancel
                        </Button>

                        <Form.Item>
                           <Button
                              htmlType="submit"
                              className={styles.buttonsCreateProfile}
                              disabled={load}
                              loading={load}
                              onClick={handleCreateMultipleMembers}
                           >
                              {load ? <>Processing</> : !record ? "Create Profile" : "Edit Member"}
                           </Button>
                        </Form.Item>
                     </div>
                  </Col>
               </Row>
            </div>
         )
      }
   ];

   return (
      <Modal
         open={visible}
         width={800}
         closable={false}
         onCancel={(() => onCancel("newMemberModal"), form.resetFields())}
      >
         <Row gutter={16} justify="center">
            <Col span={23}>
               {" "}
               <h1
                  className="modal-heading"
                  style={{
                     color: "#4158CF",
                     textAlign: "center",
                     marginLeft: "50px",
                     fontSize: "22px"
                  }}
               >
                  Add New Member
               </h1>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
               {" "}
               <Button type="text" onClick={() => onCancel("newMemberModal")}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                  >
                     <path
                        d="M17.1855 1.16675L1.18555 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M1.18555 1.16675L17.1855 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </Button>
            </Col>
         </Row>
         <Tabs defaultActiveKey="1" items={items} />
      </Modal>
   );
}

export { NewMember };
