export const isPublicSurveyRoute = () => {
   return Boolean(/^\/public-survey\/([^/]+)(\/[^/]+)*\/?$/.test(window.location.pathname));
};

export const Constants = {
   ARABIC_LANGUAGE_ID: "b63818cb-da8f-44d6-a578-c9b83e39f5eb",
   ENGLISH_LANGUAGE_ID: "ecfb845a-86f0-476c-a4df-f101d35a96f9",

   // AWS identifier for KAP Survey Platform
   PLATFORM_ID: "006d1c1e-81b6-4d87-b4b0-f1850543f2cc"
};
