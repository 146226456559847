import React from "react";
import "./Buttons.scss";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const ShowMoreLessButton = ({ onClick, shouldShowMore }) => {
   return (
      <div onClick={onClick} className="showMoreOrLess">
         {shouldShowMore ? "Show more" : "Show less"}
         {shouldShowMore ? <DownOutlined /> : <UpOutlined />}
      </div>
   );
};

export default ShowMoreLessButton;
