/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import AuthService from "../../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../../app/pages/auth/userRoles.enum";

export function AdminAsideMenuList({ data, layoutProps }) {
   const location = useLocation();
   const navigate = useNavigate();

   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
         : "";
   };

   const [isWorkspaceSelection, setIsWorkspaceSelection] = useState(false);

   useEffect(() => {
      if (location.pathname === "/workspace") {
         setIsWorkspaceSelection(true);
      } else {
         setIsWorkspaceSelection(false);
      }
   }, [location]);

   const handleBackToWorkspaces = () => {
      if (
         AuthService.isAuthorized([
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ])
      ) {
         navigate("/admin-workspaces");
      } else {
         navigate("/workspace");
      }
   };

   return (
      <>
         {/* begin::Menu Nav */}
         <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {!isWorkspaceSelection && data.length !== 0
               ? data.map((menuItem) => {
                    if (menuItem.type === "divider") {
                       return (
                          AuthService.isAuthorized(menuItem.role) &&
                          menuItem?.logic === "enteredProject" && (
                             <li key={menuItem.id} className="menu-section ">
                                <h4 className="menu-text">{menuItem.title}</h4>
                                <i className="menu-icon flaticon-more-v2"></i>
                             </li>
                          )
                       );
                    } else {
                       return (
                          AuthService.isAuthorized(menuItem.role) &&
                          menuItem?.logic === "enteredProject" && (
                             <li
                                key={menuItem.id}
                                className={`menu-item ${menuItem.subMenus?.length > 0 &&
                                   "menu-item-submenu "} ${getMenuItemActive(
                                   menuItem.url,
                                   menuItem.children?.length > 0 ? true : false
                                )}`}
                                aria-haspopup="true"
                             >
                                <NavLink
                                   className={`menu-link d-flex align-items-center ${menuItem.isDisabled &&
                                      " disabled"} ${menuItem.subMenus?.length > 0 &&
                                      "menu-toggle"}`}
                                   to={menuItem.url}
                                >
                                   <span className="svg-icon svg-icon-lg mr-4">
                                      {React.createElement(menuItem.icon, {
                                         active: checkIsActive(location, menuItem.url)
                                      })}
                                   </span>
                                   <span className="menu-text">{menuItem.title}</span>
                                   {menuItem.subMenus?.length > 0 && <i className="menu-arrow" />}
                                </NavLink>

                                <div className="menu-submenu ">
                                   <i className="menu-arrow" />
                                   <ul className="menu-subnav">
                                      {/*begin::2 Level*/}
                                      {menuItem.subMenus?.length > 0 &&
                                         menuItem.subMenus.map((subMenu) => {
                                            return (
                                               <li
                                                  key={subMenu.id}
                                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                     subMenu.url,
                                                     subMenu.children?.length > 0 ? true : false
                                                  )}`}
                                                  aria-haspopup="true"
                                                  data-menu-toggle="hover"
                                               >
                                                  <NavLink
                                                     className={`menu-link menu-toggle ${menuItem.isDisabled &&
                                                        " disabled"}`}
                                                     to={subMenu.url}
                                                  >
                                                     <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                     </i>
                                                     <span className="menu-text">
                                                        {subMenu.title}
                                                     </span>
                                                     {subMenu.children?.length > 0 && (
                                                        <i className="menu-arrow" />
                                                     )}
                                                  </NavLink>
                                                  {subMenu.children?.length > 0 && (
                                                     <div className="menu-submenu ">
                                                        <i className="menu-arrow" />
                                                        <ul className="menu-subnav">
                                                           {/*begin::3 Level*/}
                                                           {subMenu.children?.map((childItem) => {
                                                              return (
                                                                 <li
                                                                    key={childItem.id}
                                                                    className={`menu-item  ${getMenuItemActive(
                                                                       childItem.url
                                                                    )}`}
                                                                    aria-haspopup="true"
                                                                 >
                                                                    <NavLink
                                                                       className={`menu-link ${childItem.isDisabled &&
                                                                          " disabled"}`}
                                                                       to={childItem.url}
                                                                    >
                                                                       <i className="menu-bullet menu-bullet-dot">
                                                                          <span />
                                                                       </i>
                                                                       <span className="menu-text">
                                                                          {childItem.title}
                                                                       </span>
                                                                    </NavLink>
                                                                 </li>
                                                              );
                                                           })}

                                                           {/*end::3 Level*/}
                                                        </ul>
                                                     </div>
                                                  )}
                                               </li>
                                            );
                                         })}
                                      {/*end::2 Level*/}
                                   </ul>
                                </div>
                             </li>
                          )
                       );
                    }
                 })
               : null}

            {/*end::1 Level*/}

            {/* Components */}
            {/* begin::section */}

            {/* end:: section */}
         </ul>

         <div className="aside-footer d-flex flex-column p-2">
            {/* <OverlayTrigger
               placement="right"
               overlay={<Tooltip id="toggle-quick-panel">Dark/Light Mode</Tooltip>}
            >
               <a
                  onClick={() => handleThemeColorChange()}
                  className="btn disabled btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_panel_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
               >
                  {isDarkModeOn ? (
                     <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl("/media/svg/custom-icons/sun.svg")} />
                     </span>
                  ) : (
                     <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl("/media/svg/custom-icons/moon.svg")} />
                     </span>
                  )}
               </a>
            </OverlayTrigger> */}

            {location.pathname === "/admin-payment-plans" ||
            location.pathname === "/admin-user-management" ||
            location.pathname === "/admin-workspaces" ||
            location.pathname === "/admin-organization-management" ||
            location.pathname === "/admin-deleted-items" ||
            location.pathname === "/admin-user-management/new-member" ||
            location.pathname === "/admin-workspace" ||
            location.pathname === "/workspace/new-work-space" ||
            location.pathname === "/account-summary" ||
            location.pathname === "/profile-information" ||
            location.pathname === "/subscription-plans" ||
            location.pathname === "/login-information" ? (
               <></>
            ) : (
               <>
                  {AuthService.isAuthorized([
                     UserRolesEnum.SuperAdmin,
                     UserRolesEnum.Organization,
                     UserRolesEnum.JuniorProjectManager,
                     UserRolesEnum.AdminOfOrganization,
                     UserRolesEnum.SeniorProjectManager,
                     UserRolesEnum.ProjectManager,
                     UserRolesEnum.Guest
                  ]) && (
                     <button
                        className="back-to-button"
                        onClick={() => navigate("/project-selection")}
                     >
                        {" "}
                        <span style={{ marginRight: "3px" }}>
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                           >
                              <path
                                 d="M1.13086 10.8886H11.4166C12.629 10.8886 13.7918 10.407 14.6491 9.54964C15.5064 8.69234 15.988 7.52957 15.988 6.31716C15.988 5.10474 15.5064 3.94198 14.6491 3.08467C13.7918 2.22736 12.629 1.74573 11.4166 1.74573H7.988"
                                 stroke="#F2F2F2"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                              <path
                                 d="M4.55943 7.45996L1.13086 10.8885L4.55943 14.3171"
                                 stroke="#F2F2F2"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                        </span>
                        Back to Projects
                     </button>
                  )}
                  <button className="back-to-button" onClick={handleBackToWorkspaces}>
                     {" "}
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        style={{ marginRight: "3px" }}
                     >
                        <path
                           d="M1.13086 10.8886H11.4166C12.629 10.8886 13.7918 10.407 14.6491 9.54964C15.5064 8.69234 15.988 7.52957 15.988 6.31716C15.988 5.10474 15.5064 3.94198 14.6491 3.08467C13.7918 2.22736 12.629 1.74573 11.4166 1.74573H7.988"
                           stroke="#F2F2F2"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                        <path
                           d="M4.55943 7.45996L1.13086 10.8885L4.55943 14.3171"
                           stroke="#F2F2F2"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                     Back to Workspaces
                  </button>
               </>
            )}
         </div>
         {/* end::Menu Nav */}
      </>
   );
}
