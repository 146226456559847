import { Button, Flex } from "antd";
import { useRef, useState } from "react";

const DeletedActionButton = ({ children, onClick, disabled = false }) => {
   const [loading, setLoading] = useState(false);
   const mounted = useRef();

   const onClickHandler = async () => {
      if (onClick) {
         setLoading(true);
         await onClick?.();
         if (mounted.current) {
            setLoading(false);
         }
      }
   };
   return (
      <Button
         ref={mounted}
         onClick={onClickHandler}
         loading={loading}
         disabled={disabled || loading}
         type="text"
         style={{
            width: "32px",
            height: "32px"
         }}
      >
         {!loading && (
            <Flex justify="center" align="center">
               {children}
            </Flex>
         )}
      </Button>
   );
};

export default DeletedActionButton;
