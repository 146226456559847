import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { PlanDetail } from "./components/planSection/PlanDetail";
import { httpService } from "../../../base/services/httpService.service";
import { SessionService } from "../../../base/services/session.service";
// import { Switch } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { sixMonthImage, threeMonthImage } from "../../../assets/images";
import { Button, Radio, Switch } from "antd";

function RegisterStep2({ goToPayment, goToStep }) {
   const nav = useNavigate();
   const location = useLocation();

   const [selectedPlan, setSelectedPlan] = useState({});
   const [planOptions, setPlanOptions] = useState([]);
   const [load, setLoad] = useState(false);
   const [isYearly, setIsYearly] = useState(true);
   const addPaymentDetails = SessionService.getItem("addPaymentDetails");

   const handlePlanSelection = (e, plan, annuallyPrice) => {
      e.preventDefault();
      setSelectedPlan({ name: plan, annuallyPrice: annuallyPrice });
      console.log(plan);
      setSelectedPlan({ name: plan, annuallyPrice: annuallyPrice });
   };
   const togglePricingPlan = () => {
      setIsYearly(!isYearly);
   };

   // Updated function to handle radio button change
   const handlePricingToggle = (e) => {
      setIsYearly(e.target.value === "yearly");
      localStorage.setItem("isYearly", e.target.value === "yearly");
   };

   const goToPay = () => {
      setLoad(true);

      console.log("planOptions::", planOptions);
      if (selectedPlan != {}) {
         setTimeout(() => {
            SessionService.setItem(
               "registeringPlan",
               planOptions.find((p) => p.name === selectedPlan.name)
            );
            goToPayment();
            // nav("/subscription/stripe");
         }, 400);
      }
      setLoad(false);
   };

   const emitStep = (step) => {
      goToStep(step);
   };

   useEffect(() => {
      httpService.get("/Account/getPackets", (res) => {
         console.log(res.data);
         setPlanOptions(res.data);
      });
   }, []);

   function isEmpty(obj) {
      for (var prop in obj) {
         if (obj.hasOwnProperty(prop)) {
            return false;
         }
      }
      return true;
   }
   return (
      <>
         {/* <div className="pricing-toggle">
            <Switch
               checked={localStorage.setItem("isYearly", isYearly)}
               onChange={togglePricingPlan}
               checkedChildren="Yearly"
               unCheckedChildren="Monthly"
            />
           
         </div> */}
         <div style={{ textAlign: "left" }}>
            {" "}
            {SessionService.getItem("isOnSecondStep") || !addPaymentDetails ? (
               <div
                  style={{ textAlign: "left", color: "#5770CB" }}
                  onClick={() => nav(`/auth/login`)}
               >
                  {" "}
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="17"
                     height="17"
                     viewBox="0 0 17 17"
                     fill="none"
                  >
                     <path
                        d="M14.7358 8.2339L2.73584 8.2339M2.73584 8.2339L8.10255 2.86719M2.73584 8.2339L8.10255 13.6006"
                        stroke="#5770CB"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>{" "}
                  Go back to profile information
               </div>
            ) : (
               <div
                  style={{
                     textAlign: "left",
                     marginBottom: "40px",
                     cursor: "pointer",
                     color: "#5770CB"
                  }}
                  onClick={() => emitStep(0)}
               >
                  {" "}
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="17"
                     height="17"
                     viewBox="0 0 17 17"
                     fill="none"
                  >
                     <path
                        d="M14.7358 8.2339L2.73584 8.2339M2.73584 8.2339L8.10255 2.86719M2.73584 8.2339L8.10255 13.6006"
                        stroke="#5770CB"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>{" "}
                  Go back to profile information
               </div>
            )}
         </div>
         <h1 style={{ margin: "40px", textAlign: "center" }} className="heading-select-plan">
            Select your plan
         </h1>
         <div className="pricing-toggle" style={{ textAlign: "center" }}>
            <Radio.Group onChange={handlePricingToggle} value={isYearly ? "yearly" : "monthly"}>
               <Radio value="yearly">Billed annually</Radio>
               <Radio value="monthly">Billed monthly</Radio>
            </Radio.Group>
         </div>
         <div
            className="register-payment-section"
            style={{ flexDirection: "row-reverse", marginTop: "25px" }}
         >
            {planOptions.map((plan, i) => {
               return (
                  <Button
                     key={i}
                     name="plan"
                     value={plan.name}
                     onClick={(e) => handlePlanSelection(e, plan.name, plan.annuallyPrice)}
                     className={`plan-button ${selectedPlan === plan.name ? "selected" : ""}`}
                  >
                     <div className="plan-title">
                        <span className="months">{isYearly ? "1 month free" : "1 month free"}</span>
                        <span>{plan.name} </span>
                     </div>
                     <div className="payment">
                        <span>USD</span>{" "}
                        <span className="annuallyPrice">
                           ${isYearly ? plan.annuallyPrice : plan.monthlyPrice}
                        </span>
                        <span style={{ color: "#999999" }}> per month</span>
                        {/* ${plan.monthlyPrice} / mo. */}
                     </div>
                     <div className="content">
                        <p
                           style={{
                              color: "#DE8344",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "600"
                           }}
                        >
                           This plans includes:
                        </p>
                        <p>
                           <AiOutlineCheckCircle />
                           {plan.workspaceLimit != 0 ? plan.workspaceLimit : "Unlimited"} workspaces
                        </p>
                        <p>
                           <AiOutlineCheckCircle />
                           {plan.membersLimit != 0 ? plan.membersLimit : "Unlimited"}{" "}
                           users/workspace
                        </p>
                        <p>
                           <AiOutlineCheckCircle />
                           {plan.databaseLimit != 0 ? (
                              <>{plan?.databaseLimit} GB data storage limit</>
                           ) : (
                              <>Unlimited data storage</>
                           )}
                        </p>
                     </div>
                     <PlanDetail planData={plan} />
                  </Button>
               );
            })}
         </div>
         {isYearly && !isEmpty(selectedPlan) && (
            <div>
               {/* <div className="total-per-year" style={{textAlign:"center"}}>
               <span>Total per year: USD ${selectedPlan.annuallyPrice * 12}</span>
            </div> */}
               <p
                  style={{ textAlign: "center", marginTop: "20px" }}
                  className="paragraph-total-year"
               >
                  Total per year: <b>USD ${selectedPlan.annuallyPrice * 12}</b>
               </p>
            </div>
         )}

         <div
            className="register-button-group"
            style={{
               marginTop: "5%",
               width: "100%",
               display: "flex",
               justifyContent: "center" // Horizontal centering
            }}
         >
            <Button
               disabled={!selectedPlan.name}
               loading={load}
               onClick={() => {
                  localStorage.setItem("isYearly", isYearly);
                  goToPay();
               }}
               className="kapSurveyPrimaryButton"
               style={{
                  backgroundColor: selectedPlan.name ? "#DE8344" : "#ccc",
                  cursor: selectedPlan.name ? "pointer" : "not-allowed",
                  border: "none",
                  color: "white",
                  fontSize: "600",
                  textAlign: "center",
                  justifyContent: "center"
               }}
            >
               {/* Review & Pay */}
               {location.pathname === "/auth/registration" ? "Start your free trial" : "Continue"}
            </Button>
         </div>
      </>
   );
}

export default RegisterStep2;
