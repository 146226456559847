import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { httpService } from "../../../../base/services/httpService.service";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/withErrorBoundary";

function CreateNewProject() {
   const [projectType, setProjectType] = useState([]);

   const newProject = [
      {
         src: "/media/svg/custom-icons/projects/data-collection.png",
         alt: "data-collection",
         href: "/project-selection/create-project/",
         margin: "",
         disabled: false
      },
      {
         src: "/media/svg/custom-icons/projects/feedback-collection.png",
         alt: "feedback-collection",
         href: "",
         // href: "/project-selection/create-project/",
         margin: "5px",
         disabled: true
      }
   ];

   useEffect(() => {
      httpService.get("/Projects/getAllProjectTypes", (res) => {
         console.log(res);
         const reversedDataResult = [...res.data].reverse();
         const updatedTypes = newProject.map((type, index) => ({
            ...type,
            src: type.src,
            href: type.href + reversedDataResult[index]?.id,
            margin: type.margin,
            title: reversedDataResult[index]?.name,
            id: reversedDataResult[index]?.id
         }));

         setProjectType(updatedTypes);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="new-project-page">
         <h2>New project</h2>
         <p></p>
         <div className="project-description">
            <p>Select a project type</p>
         </div>
         <div className="project-import-build-box-group">
            {projectType.map((project, i) => {
               const handleClick = (e) => {
                  if (project.disabled) {
                     e.preventDefault();
                  }
               };

               return (
                  <Link to={project.href} key={i} onClick={handleClick}>
                     <div className={`new-project-box ${project.disabled ? "disabled" : ""}`}>
                        <div className="project-icon">
                           <img src={`${toAbsoluteUrl(project.src)}`} alt={project.alt} />
                        </div>
                        <h4>{project.title}</h4>
                     </div>
                  </Link>
               );
            })}
         </div>
      </div>
   );
}

const CreateNewProjectWithErrorBoundary = withErrorBoundary(CreateNewProject);
export { CreateNewProjectWithErrorBoundary as CreateNewProject };
