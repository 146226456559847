import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const UNIX_EPOCH = "1970-01-01"; // January 1, 1970 (the Unix Epoch)
const Functions = {
   dayjs: dayjs,

   selected: (value, choice) => {
      return value?.includes(choice);
   },

   round: (number, decimalPlaces) => {
      if (!decimalPlaces) {
         return Math.floor(number);
      }
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(number * factor) / factor;
   },

   /**
    * Returns the current date without a time component.
    * @returns {dayjs.Dayjs} dayjs object
    */
   today: () => {
      return dayjs().startOf("day");
   },

   date: (dateTime) => {
      if (typeof dateTime === "number") {
         const epoch = dayjs(UNIX_EPOCH);
         return epoch.add(dateTime, "day").startOf("day");
      }

      return dayjs(dateTime).startOf("day");
   },

   countSelected: (value) => {
      return Array.isArray(value) ? value.length : 0;
   },

   /**
    * Returns the string at the n-th position of the space_delimited_array. (The array is zero-indexed.)
    * Returns an empty string if the index does not exist.
    * @param {*} value
    * @param {number} index
    */
   selectedAt: (value, index) => {
      if (!value || !Array.isArray(value)) {
         return "";
      }
      const selectedValue = value.at(index);
      return selectedValue ?? "";
   },

   /**
    * Returns an integer equal to the 1-indexed position of the current node within the node defined by xpath.
    * @param {string} xpath - The xpath of the node to find the position of.
    * @returns {number | null} The 1-indexed position of the current node within the node defined by xpath.
    */
   position: (xpath, meta) => {
      const repeatInstanceIndex = meta?.repeatInstanceIndex;
      const parsedIndex = parseInt(repeatInstanceIndex, 10);

      switch (xpath) {
         case isNaN(parsedIndex):
            return 1;
         // position(..) provides the index of the repeat instance it was called from.
         case "":
         case "..":
            return parsedIndex;

         default:
            throw new Error(`Unsupported xpath for position function: ${xpath}`);
      }
   },

   /**
    * Returns the label value, in the active language, associated with the choice_name in the list of choices for the select_question.
    */
   jrChoiceName: (choice_name, choices) => {
      if (!choice_name || !choices) return "";
      const choice = choices?.find((item) => String(item.value) === String(choice_name));
      return choice ? choice.label : "";
   },

   /**
    * Returns True if the string contains the substring.
    */
   contains: (string, substring) => {
      return string?.includes(substring);
   },

   /**
    * Returns the response value of question name from the repeat-group group, in iteration i.
    */
   indexedRepeat: (name, group, iteration) => {
      const inputName = `${name}/${iteration}`;
      const value = group?.find((input) => {
         const inpName = Object.keys(input)[0];
         return inpName === inputName;
      })?.[inputName];

      return value;
   },

   /**
    * Joins the members of nodeset, using the string separator.
    */
   join: (separator, nodeset) => {
      if (!nodeset) {
         return null;
      }
      const values = nodeset.flatMap((node) => Object.values(node));

      return values?.join(separator);
   },

   /**
    * Returns the substring of a string beginning at the index start and
    * extending to (but not including) index end (or to the end of string if end is not provided).
    * @param {string} string - The input string to extract the substring from.
    * @param {number} start - The zero-indexed position to start extraction.
    * @param {number} [end] - The zero-indexed position to end extraction (not including this position).
    * @returns {string} - The extracted substring.
    */
   substr: (string, start, end) => {
      if (typeof string !== "string") {
         return ""; // Return an empty string if input is not a string
      }

      const strLength = string.length;

      // Ensure start is within bounds
      const startIndex = Math.max(0, Math.min(strLength, start));

      // If end is provided, ensure it is within bounds, otherwise use strLength
      const endIndex = end !== undefined ? Math.min(strLength, end) : strLength;

      // Return empty string if start is greater than end
      if (startIndex > endIndex) {
         return "";
      }

      // Return the substring
      return string.substring(startIndex, endIndex);
   },

   /**
    * Returns the number of items in nodeset. This can be used to count the number of repetitions in a repeat group.
    */
   count: (nodeset) => {
      if (Array.isArray(nodeset)) {
         return nodeset.length;
      }
      return 0;
   },

   /**
    * Truncates the fractional portion of a decimal number to return an integer.
    */
   int: (number) => {
      return Math.trunc(number);
   },

   /**
    * Returns the current datetime in ISO 8601 format, including the timezone.
    */
   now: () => {
      return dayjs();
   },

   /**
    * Returns first non-empty value of the two arg s. Returns an empty string if both are empty or non-existent.
    */
   coalesce: (arg1, arg2) => {
      return arg1 ?? arg2 ?? "";
   }
};

export const comparisonOperators = Object.freeze({
   "=": "==",
   "!=": "!==",
   ">": ">",
   "<": "<",
   ">=": ">=",
   "<=": "<="
});

const REGEX = Object.freeze({
   repeatInstanceIndex: /\/\d+$/,
   regex: /regex\(value*,\s*'(.*)'\)/,
   ifConditions: /if\s*\(([^,]+),\s*([^,]+?),\s*(if\(.*\)|[^,]+?)\)/,
   countSelected: /count-selected\(value\)/gm,
   selectedAt: /selected-at\((.*)\)/,
   jrChoiceName: /jr:choice-name\(\s*(.+?)\s*,\s*'([^']+)'\s*\)/,
   not: /not\(/gm,
   and: /\band\b/gm,
   or: /\bor\b/gm,
   div: /\bdiv\b/gm,
   mod: /\bmod\b/gm,
   value: /(?<!\d)\.(?!\.)(?!\d)/gm,
   comparison: /!=|(?<![=!><])=(?!=)/g,
   foreignInputValue: /\${([^}]+)}/gm,
   complexDates: /(\${.*}|value|today\(\)|date\(.*\))\s*(-|\+)\s*(\${.*}|value|today\(\)|date\(.*\))/,
   position: /position\((.*?)\)/,
   indexedRepeat: /indexed-repeat\(([^,]+),([^,]+),(.+)\)/,
   join: /join\(([^,]+),(.+)\)/,
   comparingEmptyString: /(relatedInputs\['[^']+'\])\s*([=!<>]+)\s*'([^']*)'/g
});

const patterns = [
   [
      REGEX.position,
      (constraint) => {
         if (constraint === "position()") {
            return "position('', meta)";
         }
         return constraint.replace(REGEX.position, "position('$1', meta)");
      }
   ],
   [
      REGEX.value,
      (constraint) => {
         if (REGEX.position.test(constraint)) {
            return constraint;
         }
         return constraint.replace(REGEX.value, "value");
      }
   ],

   [
      REGEX.regex,
      (constraint) => {
         const patternMatch = constraint.match(REGEX.regex);

         if (patternMatch && patternMatch.length > 1) {
            const [og, matched] = patternMatch;
            const regex = new RegExp(matched);
            const jsRegex = `${regex}.test(value)`;

            return constraint.replace(og, jsRegex);
         } else {
            throw new Error(constraint);
         }
      }
   ],
   [
      REGEX.ifConditions,
      (constraint) => {
         return constraint.replace(new RegExp(REGEX.ifConditions, "g"), (...match) => {
            function parseIfCondition(condition) {
               if (!REGEX.ifConditions.test(condition)) {
                  return condition?.trim(); // Return the condition if it doesn't match the if pattern
               }
               const nextMatch = Array.isArray(condition)
                  ? condition
                  : condition.match(REGEX.ifConditions);

               const [, expression, thenPart, elsePart] = nextMatch;

               const parsedThen = parseIfCondition(thenPart);
               const parsedElse = parseIfCondition(elsePart);

               // Assuming variable format is ${var_name} and value is a string or number
               const parsedExpression = expression?.trim().replace(
                  new RegExp(/\$\{([^}]+)\}\s*([=!<>]+)\s*'([^']*)'/g),

                  (__, variable, operator, value) => {
                     if (comparisonOperators[operator] === "==" && value === "") {
                        return `(typeof relatedInputs['${variable}'] === 'string' ? relatedInputs['${variable}'] == '' : !relatedInputs['${variable}'])`;
                     }
                     if (comparisonOperators[operator] === "!=" && value === "") {
                        return `(typeof relatedInputs['${variable}'] === 'string' ? relatedInputs['${variable}'] != '' : !!relatedInputs['${variable}'])`;
                     }
                     return `(relatedInputs['${variable}'] ${comparisonOperators[operator]} '${value}')`;
                  }
               );

               const exp = parsedExpression;
               const then = parsedThen === "'0'" ? 0 : parsedThen;
               const els = parsedElse === "'0'" ? 0 : parsedElse;

               return `(${exp} ? ${then} : ${els})`;
            }
            const ternaryExpression = parseIfCondition(match);

            return ternaryExpression;
         });
      }
   ],

   [REGEX.not, (constraint) => constraint.replace(REGEX.not, "!(")],
   [REGEX.and, (constraint) => constraint.replace(REGEX.and, "&&")],
   [REGEX.or, (constraint) => constraint.replace(REGEX.or, "||")],
   [REGEX.div, (constraint) => constraint.replace(REGEX.div, "/")],
   [REGEX.mod, (constraint) => constraint.replace(REGEX.mod, "%")],

   [
      REGEX.countSelected,
      (constraint) => constraint.replace(REGEX.countSelected, "countSelected(value)")
   ],

   [
      REGEX.selectedAt,
      (constraint) =>
         constraint.replace(REGEX.selectedAt, (match, params) => {
            if (!match) {
               throw new Error(constraint);
            }
            return `selectedAt(${params})`;
         })
   ],

   [
      REGEX.comparison,
      (constraint) => {
         return constraint.replace(REGEX.comparison, (match) => {
            if (match === "!=") {
               return "!==";
            } else if (match === "=") {
               return "==";
            }
            return match;
         });
      }
   ],

   [
      REGEX.complexDates,
      (constraint) => {
         return constraint.replace(REGEX.complexDates, (match, left, operator, right) => {
            const nrOfDays = 1000 * 60 * 60 * 24;

            if (match) {
               return `((${left}?.$isDayjsObject || ${right}?.$isDayjsObject) ? ((${match}) / ${nrOfDays}) : (${match}))`;
            } else {
               throw new Error(constraint);
            }
         });
      }
   ],
   [
      REGEX.jrChoiceName,
      (constraint) => {
         return constraint.replace(REGEX.jrChoiceName, (match, param1, param2) => {
            return `jrChoiceName(${param1}, meta)`;
         });
      }
   ],
   [
      REGEX.foreignInputValue,
      (constraint) => {
         return constraint.replace(REGEX.foreignInputValue, (match, inputName) => {
            if (!match) {
               throw new Error(constraint);
            }
            return `relatedInputs['${inputName}']`;
         });
      }
   ],

   [
      REGEX.indexedRepeat,
      (constraint) => {
         return constraint.replace(REGEX.indexedRepeat, (_, param1, param2, param3) => {
            const params = [param1]
               .map((param) =>
                  param.replace(/relatedInputs\['([^']+)'\]/, (_, inputName) => `'${inputName}'`)
               )
               .concat(param2, param3);
            return `indexedRepeat(${params.join(",")}, meta)`;
         });
      }
   ],

   [
      REGEX.comparingEmptyString,
      (constraint) => {
         return constraint.replace(
            REGEX.comparingEmptyString,
            (match, variable, operator, value) => {
               if ((operator === "!==" || operator === "==") && value === "") {
                  const isString = `typeof ${variable} === "string"`;

                  return `${isString} ? ${match} : ${
                     operator === "==" ? `!${variable}` : `!!${variable}`
                  }`;
               }

               return constraint;
            }
         );
      }
   ]
];

function applyReplacements(inputString) {
   let modifiedString = inputString;
   patterns.forEach(([regex, replacementFunction]) => {
      if (regex.test(modifiedString)) {
         modifiedString = replacementFunction(modifiedString);
      }
   });
   return modifiedString;
}

export { REGEX, patterns, applyReplacements, Functions };
