const Functions = {
   selected: (inputs, _name, options = {}) => {
      return Array.isArray(inputs)
         ? `${options.not ? "!" : ""}${JSON.stringify(
              inputs
           )}.some((selectedItem) => selectedItem === option.name)`
         : `${options.not ? "!" : ""}"${inputs}" === option.name`;
   },
   not: (inputs) => {
      return Array.isArray(inputs)
         ? `${JSON.stringify(inputs)}.some((selectedItem) => selectedItem !== option.name)`
         : `"${inputs}" !== option.name`;
   },
   contains: (inputs, _name, options = {}) => {
      return Array.isArray(inputs)
         ? `${options.not ? "!" : ""}${JSON.stringify(
              inputs
           )}.some((selectedItem)=>selectedItem.includes(option.name))`
         : `${options.not ? "!" : ""}"${inputs}".includes(option.name)`;
   }
};

const REGEX = Object.freeze({
   value: /(?<!\d)\.(?!\.)(?!\d)/,
   foreignInputValue: /\${([^}]+)}/gm,
   notSelected: /not\(\s*selected\(([^)]+)\)\s*\)/,
   notContains: /not\(\s*contains\(([^)]+)\)\s*\)/,
   and: /\b(and|selected|contains)\b/,
   not: /not\(([^)]+)\)/gm,
   columnFilter: /(\w+)\s*(<=|>=|<|>|=|!=)\s*\${(\w+)}/
});

const patterns = [
   [
      REGEX.value,
      (choiceFilter) => {
         return choiceFilter.replace(REGEX.value, "value");
      }
   ],
   [
      REGEX.foreignInputValue,
      (choiceFilter) => {
         return choiceFilter.replace(REGEX.foreignInputValue, (match, inputName) => {
            if (!inputName) {
               throw new Error(choiceFilter);
            }
            return `relatedInputs['${inputName}']`;
         });
      }
   ],
   [
      REGEX.notSelected,
      (choiceFilter) => {
         return choiceFilter.replace(REGEX.notSelected, "selected($1, {not:true})");
      }
   ],
   [
      REGEX.notContains,
      (choiceFilter) => {
         return choiceFilter.replace(REGEX.notContains, "contains($1, {not:true})");
      }
   ],
   [
      REGEX.and,
      (choiceFilter) => {
         const conditionsList = choiceFilter.split("and");

         return conditionsList.reduce((acc, condition, index) => {
            if (index === 0) {
               return `name.filter((option, index)=>eval(${condition}))`;
            }
            return `${acc}.filter((option, index)=>eval(${condition}))`;
         }, "");
      }
   ],
   [
      REGEX.not,
      (choiceFilter) => {
         return choiceFilter.replace(REGEX.not, (match, group1) => {
            const param1 = group1.split("=")[0];
            if (!param1) {
               return match;
            }
            return `not(${param1}, name)`;
         });
      }
   ]
];

const applyReplacements = (inputString) => {
   let modifiedString = inputString;
   patterns.forEach(([regex, replacementFunction], i) => {
      if (regex.test(modifiedString)) {
         modifiedString = replacementFunction(modifiedString);
      }
   });
   return modifiedString;
};

export { REGEX, patterns, applyReplacements, Functions };
