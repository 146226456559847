import { notification } from "antd";
import dayjs from "dayjs";
import Dexie from "dexie";

/**
 * @typedef {{ surveyId: string, draftId: string, draftTitle: string, draftDescription: string, formData: any, renderedElementIds: string[] }} DraftData
 */

// Initialize the database
const db = new Dexie("DraftsDB");
db.version(1).stores({
   drafts:
      "++id, surveyId, draftId, draftTitle, draftDescription, formData, [surveyId+draftId], timestamp, renderedElementIds"
});

export const db_drafts = db.drafts;

/**
 * Save draft function
 * @param {DraftData} draftData
 */
export const saveDraft = async (draftData) => {
   const { surveyId, draftId } = draftData;
   const existingDraft = await db.drafts
      .where("[surveyId+draftId]")
      .equals([surveyId, draftId])
      .first();

   if (existingDraft) {
      // Update the existing draft
      await db.drafts.update(existingDraft.id, { ...draftData, timestamp: dayjs().toISOString() });
   } else {
      // Insert a new draft
      await db.drafts.put({ ...draftData, timestamp: dayjs().toISOString() });
   }
};

/**
 * Load draft function
 * @param {string} surveyId - The ID of the survey.
 * @param {string} draftId - The ID of the draft.
 * @returns {Promise<DraftData|null>} - The draft data if found, otherwise null.
 */
export const loadDraft = async (surveyId, draftId) => {
   const draft = await db.drafts
      .where("[surveyId+draftId]")
      .equals([surveyId, draftId])
      .first();
   if (draft) {
      return draft;
   }
   return null;
};

/**
 * Discard draft function
 * @param {string} surveyId - The ID of the survey.
 * @param {string} draftId - The ID of the draft.
 * @returns {Promise<void>} - A promise that resolves when the draft is deleted.
 */
export const deleteDraft = async (surveyId, draftId) => {
   await db.drafts
      .where("[surveyId+draftId]")
      .equals([surveyId, draftId])
      .delete()
      .then(() => {
         notification.success({
            message: "Draft discarded"
         });
      })
      .catch((error) => {
         notification.error({ message: "Failed to discard draft", description: error.message });
      });
};

/**
 * Get all drafts for a survey
 * @param {string} surveyId - The ID of the survey.
 * @returns {Promise<DraftData[]>} - An array of all drafts for the specified survey.
 */
export const getAllDraftsForSurvey = async (surveyId) => {
   const drafts = await db.drafts.where({ surveyId }).toArray();
   return drafts;
};
