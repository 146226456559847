import axios from "axios";
import AuthService from "../services/authentication.service";
import Toast from "../components/Toast";
import { isPublicSurveyRoute } from "../../utils/constants";

const { REACT_APP_BASE_URL, REACT_APP_FILE_MANAGEMENT } = process.env;

const https = axios.create({
   timeout: 60000,
   headers: { "Content-Type": "application/json" }
});

export const setupInterceptor = (store) => {
   https.interceptors.request.use((config) => {
      const token = AuthService.getAccessToken();
      const organizationId = AuthService.getOrganizationId();

      if (token !== null || token !== "") {
         config.headers.Authorization = `Bearer ${token}`;
      }

      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-OrganizationId"] = organizationId;

      if (config.url && config.url.startsWith("/FileData/getFile")) {
         config.baseURL = REACT_APP_FILE_MANAGEMENT;
      } else {
         config.baseURL = REACT_APP_BASE_URL;
      }

      return config;
   });

   https.interceptors.response.use(
      (response) => {
         const contentType = response.headers["content-type"];
         if (
            contentType &&
            contentType.includes(
               "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
         ) {
            return response;
         }

         if (!response.data.isSuccess) {
            const errMessages = response.data.errors.join("");
            Toast.warning(errMessages);
         }
         return response;
      },
      (error) => {
         if (!navigator.onLine && !isPublicSurveyRoute()) {
            Toast.warning("Check your internet connection");
         }
         return Promise.reject(error);
      }
   );
};

export default https;
