import React from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import "./OrganizationManagement.scss";
import { Row, Col } from "antd";

const { Option } = Select;

const CompanyInfoModal = ({ visible, handleOk, handleCancel }) => {
   const onFinish = (values) => {
      console.log("Received values:", values);
      // You can handle the form submission logic here
      handleOk();
   };

   return (
      <Modal visible={visible} width={800} closable={false} onOk={handleOk} onCancel={handleCancel}>
         <Row gutter={16}>
            <Col span={12}>
               {" "}
               <h1 className="modal-heading">Profile information</h1>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
               {" "}
               <Button type="text" onClick={handleCancel}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                  >
                     <path
                        d="M17.1855 1.16675L1.18555 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M1.18555 1.16675L17.1855 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </Button>
            </Col>
         </Row>
         <Form
            name="companyInfoForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
         >
            <span className="form-heading">Personal Information</span>
            <Row gutter={16}>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Full Name</span>}
                     name="fullName"
                     rules={[{ required: true, message: "Please enter your full name!" }]}
                  >
                     <Input placeholder="Type your name" />
                  </Form.Item>
               </Col>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Phone Number</span>}
                     name="phoneNumber"
                     rules={[{ required: true, message: "Please enter your phone number!" }]}
                  >
                     <Input placeholder="Type your phone number" />
                  </Form.Item>
               </Col>
            </Row>
            <Row gutter={16}>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Role with Organization</span>}
                     name="role"
                     rules={[{ required: true, message: "Please enter your role!" }]}
                  >
                     <Input placeholder="Enter your role" />
                  </Form.Item>
               </Col>
            </Row>

            <hr />

            <span className="form-heading">Company Information</span>
            <Row gutter={16}>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Type of Organization</span>}
                     name="typeOfOrganization"
                     rules={[{ required: true, message: "Please enter the type of organization!" }]}
                  >
                     <Input placeholder="Select your organization" />
                  </Form.Item>
               </Col>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Organization Name</span>}
                     name="organizationName"
                     rules={[{ required: true, message: "Please enter the organization name!" }]}
                  >
                     <Input placeholder="Enter your organization name" />
                  </Form.Item>
               </Col>
            </Row>
            <Row gutter={16}>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={<span className="label-text">Country</span>}
                     name="country"
                     rules={[{ required: true, message: "Please enter the country!" }]}
                  >
                     <Input placeholder="Select your country" />
                  </Form.Item>
               </Col>
            </Row>

            <hr />
            <Form.Item>
               <Row gutter={16}>
                  <Col xs={24} sm={5}>
                     {" "}
                     <Button type="primary" htmlType="submit" className="kapSurveyPrimaryButton">
                        Update info
                     </Button>
                  </Col>
                  <Col xs={24} sm={19}>
                     {" "}
                     <Button
                        type="default"
                        className="org-mng-cancel-button"
                        onClick={handleCancel}
                        style={{ height: "44px" }}
                     >
                        Cancel
                     </Button>
                  </Col>
               </Row>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default CompanyInfoModal;
