import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import { Outlet } from "react-router-dom";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { HeaderMobile } from "./header-mobile/HeaderMobile";
// import { AdminAside } from "./aside/AdminAside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { ScrollTop } from "./extras/ScrollTop";

export function WorkspaceLayout({ children }) {
   const uiService = useHtmlClassService();
   const layoutProps = useMemo(() => {
      return {
         selfLayout: objectPath.get(uiService.config, "self.layout"),
         subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
         contentCssClasses: uiService.getClasses("content", true),
         contentContainerClasses: uiService.getClasses("content_container", true),
         contentExtended: objectPath.get(uiService.config, "content.extended")
      };
   }, [uiService]);

   useEffect(() => {
      document.body.classList.remove("aside-minimize");
   }, []);

   return layoutProps.selfLayout !== "blank" ? (
      <>
         {/*begin::Main*/}
         <HeaderMobile />

         <div className="d-flex flex-column flex-root">
            {/*begin::Page*/}
            <div className="d-flex flex-row flex-column-fluid page">
               {/* <AdminAside onMenuMinimize={checkIsMenuMinimized} /> */}
               {/*begin::Wrapper*/}
               <div
                  className={`d-flex flex-column flex-row-fluid wrapper pl-0`}
                  // className="w-100"
                  id="kt_wrapper"
               >
                  {/*begin::Content*/}
                  <div
                     id="kt_content"
                     className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                  >
                     {layoutProps.subheaderDisplay && <SubHeader />}

                     {/*begin::Entry*/}
                     {layoutProps.contentExtended && <>{children}</>}

                     {!layoutProps.contentExtended && (
                        <div
                           className="d-flex flex-column-fluid"
                           style={{
                              backgroundColor: "#eef2ff",
                              display: "flex",
                              justifyContent: "center"
                           }}
                        >
                           {/*begin::Container*/}
                           <div className={layoutProps.contentContainerClasses}>
                              <Outlet />
                           </div>
                           {/*end::Container*/}
                        </div>
                     )}

                     {/*end::Entry*/}
                  </div>
                  {/*end::Content*/}
                  <Footer />
               </div>
               {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
         </div>

         <ScrollTop />

         {/*end::Main*/}
         <LayoutInit />
      </>
   ) : (
      // BLANK LAYOUT
      <div className="d-flex flex-column flex-root">{children}</div>
   );
}
