//TODO: Refactor this file
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Select, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import { months } from "../../helpers/months";
import { httpService } from "../../base/services/httpService.service";
import AuthService from "../../base/services/authentication.service";
import { createQueryParams } from "../../helpers/queryParams";
import { ReactComponent as EnumeratorsIcon } from "../../assets/icons/enumerators-icon.svg";
import { ReactComponent as WorkspacesIcon } from "../../assets/icons/workspaces-icon.svg";
import { withErrorBoundary } from "../ErrorBoundary/withErrorBoundary";

import "./index.scss";

const AccountSummary = () => {
   const organizationId = AuthService.getOrganizationId();
   const [workSpaces, setWorkSpaces] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [filterValue, setFilterValue] = useState({
      year: null,
      month: null,
      workspace: null
   });

   const [dashboardStatistics, setDashboardStatistics] = useState({
      workspaceLimit: 0,
      currentWorkspaces: 0,
      totalSurveys: 0,
      totalBeneficiaries: 0,
      totalEnumerators: 0,
      totalProjects: 0,
      dataUsedPercentage: 0,
      dataUsageLimit: 0
   });
   const getDataCollectors = useCallback(async () => {
      setIsLoading(true);
      const queryParams = createQueryParams(filterValue);

      const params = new URLSearchParams({
         organizationId
      });
      httpService
         .get(
            `/Organizations/getDashboardStatistics?${params.toString()}&${queryParams}`,
            (res) => {
               setDashboardStatistics(res.data);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, [filterValue]);

   const chartData = {
      options: {
         dataLabels: {
            enabled: false
         },
         chart: {
            type: "donut",
            width: "500px",
            height: "500px"
         },
         legend: {
            position: "bottom",
            fontSize: "18px"
         },
         labels: ["Available", "Used"],
         colors: ["#D9E3FF", "#5770CB"],
         plotOptions: {
            pie: {
               donut: {
                  size: "65%",
                  labels: {
                     show: true,

                     total: {
                        show: true,
                        showAlways: true,
                        formatter: (w) => {
                           const { seriesTotals = {} } = w.globals;
                           if (seriesTotals[0] === 5) return `5GB`;
                           if (seriesTotals[0] === 0) return "No limit";
                           const sum = seriesTotals.reduce((a, b) => a + b, 0);
                           const avg = sum / seriesTotals.length || 0;
                           return `${Math.round(avg)}%`;
                        }
                     }
                  }
               }
            }
         },
         yaxis: {
            show: true
         }
      }
   };

   useEffect(() => {
      setIsLoading(true);
      httpService
         .get("/Workspaces/getMyWorkspaces", (res) => {
            setWorkSpaces(res.data);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   useEffect(() => {
      getDataCollectors();
   }, [filterValue]);

   const handleChange = useCallback((value, key) => {
      setFilterValue((prevState) => ({ ...prevState, [key]: value }));
   }, []);

   return (
      <div className="accountSummary accountSettings">
         <p className="title">Account summary</p>
         <div className="usageStatistics">
            <Row className="mt-8 mb-8" justify="space-between" gutter={[24, 24]}>
               <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <h6>Usage statistics</h6>
               </Col>
               <Col xs={24} sm={24} md={12} lg={12} xl={6} className="verticalLayout">
                  <label className="label-text">Select a workspace</label>
                  <Select
                     placeholder="All workspaces"
                     options={workSpaces.map((workspace) => {
                        return {
                           value: workspace.id,
                           label: workspace.name
                        };
                     })}
                     allowClear
                     onChange={(value) => handleChange(value, "workspace")}
                  />
               </Col>
               <Col xs={24} sm={24} md={12} lg={12} xl={6} className="verticalLayout">
                  <label className="label-text">Select a year</label>
                  <Select
                     placeholder="Years"
                     options={[
                        {
                           value: "2023",
                           label: "2023"
                        },
                        {
                           value: "2024",
                           label: "2024"
                        }
                     ]}
                     allowClear
                     onChange={(value) => handleChange(value, "year")}
                  />
               </Col>
               <Col xs={24} sm={24} md={12} lg={12} xl={6} className="verticalLayout">
                  <label className="label-text">Select a month</label>
                  <Select
                     placeholder="All months"
                     options={months.map((month) => {
                        return {
                           value: month.value,
                           label: month.name
                        };
                     })}
                     allowClear
                     onChange={(value) => handleChange(value, "month")}
                  />
               </Col>
               {isLoading ? (
                  <Spin
                     style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        width: "100%"
                     }}
                  />
               ) : (
                  <>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box">
                           <h4>Memory</h4>
                           <div id="chart" className="chart">
                              <ReactApexChart
                                 options={chartData.options}
                                 series={[
                                    dashboardStatistics.dataUsageLimit,
                                    dashboardStatistics.dataUsedPercentage
                                 ]}
                                 type="donut"
                                 height={240}
                              />
                           </div>
                        </div>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box">
                           <h4>Surveys</h4>
                           <h5>{dashboardStatistics.totalSurveys}</h5>
                           <p>Average monthly surveys</p>
                        </div>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box">
                           <h4>Beneficiaries</h4>
                           <h5>{dashboardStatistics.totalBeneficiaries}</h5>
                           <p>Average beneficiaries served</p>
                        </div>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box minibox">
                           <div className="dataDescription">
                              <h4>{dashboardStatistics.totalEnumerators}</h4>
                              <span>Enumerators</span>
                           </div>
                           <EnumeratorsIcon />
                        </div>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box minibox">
                           <div className="dataDescription">
                              <h4>
                                 {dashboardStatistics.currentWorkspaces}/
                                 <span
                                    style={{
                                       color: "#9BACEB"
                                    }}
                                 >
                                    {dashboardStatistics.workspaceLimit}
                                 </span>
                              </h4>
                              <span>Workspaces</span>
                           </div>
                           <WorkspacesIcon />
                        </div>
                     </Col>
                     <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <div className="box minibox">
                           <div className="dataDescription">
                              <h4>{dashboardStatistics.totalProjects}</h4>
                              <span>Projects</span>
                           </div>
                           <WorkspacesIcon />
                        </div>
                     </Col>
                  </>
               )}
            </Row>
         </div>
      </div>
   );
};

export default withErrorBoundary(AccountSummary);
