import React, { Fragment, useMemo, useState } from "react";
import ItemSelector from "../question/question-preview/ItemSelector";
import ShowMoreLessButton from "../../../../../ui/Buttons/ShowMoreLess";
import { editDataStore, useTranslateStore } from "../../surveyStore";
import { Constants } from "../../../../../utils/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { InputType } from "../../../enums/itemTypes.enum";
import useEvaluateConstraint from "../question/question-preview/hooks/useEvaluateConstraint";

import "./Section.scss";

const getRepeatedQuestions = (innerItems) => {
   if (!innerItems) return [];

   return innerItems.flatMap((item) => {
      const { surveyItem } = item;
      if (!surveyItem?.answers) return [];

      return surveyItem.answers.map((answer) => {
         if (!answer) return {};

         return {
            ...item,
            surveyItem: {
               ...surveyItem,
               questionLabels: [...surveyItem.questionLabels],
               answers: [answer]
            },
            answer: {
               ...item.answer,
               id: answer.answerDetail?.answerId,
               answerDetail: {
                  answerMatrices: answer.answerDetail?.answerMatrices,
                  answerRatings: answer.answerDetail?.answerRatings,
                  choices: answer.answerDetail?.choices,
                  filePaths: answer.answerDetail?.filePaths,
                  number: answer.answerDetail?.number,
                  questionChoice: answer.answerDetail?.questionChoice,
                  secondChoices: answer.answerDetail?.secondChoices,
                  text: answer.answerDetail?.text
               }
            }
         };
      });
   });
};

function Section({ surveyItem, hasErrors, submissionId, ...props }) {
   const [showRepeatedGroup, setShowRepeatedGroup] = useState(true);

   const isRepeat = !!surveyItem.repeat_count;
   const { toLanguage } = useTranslateStore();
   const surveyBaseLanguage = editDataStore((state) => state.surveyBaseLanguage);

   const hasSkipLogic = surveyItem.surveyItem.relevant;
   const isQuestionSubmitted = useEvaluateConstraint(hasSkipLogic, true);

   const isArabicLanguage = !toLanguage
      ? surveyBaseLanguage === Constants.ARABIC_LANGUAGE_ID
      : toLanguage === Constants.ARABIC_LANGUAGE_ID;

   const repeatCount = useEvaluateConstraint(surveyItem.repeat_count, true);

   const sortedSurveyItems = useMemo(() => {
      if ((isRepeat && !repeatCount && !submissionId) || !surveyItem?.innerItems) {
         return [];
      }

      if (isRepeat && !submissionId) {
         const repeatedItems = Array.from({ length: repeatCount }, () => [
            ...surveyItem.innerItems
         ]);

         repeatedItems.forEach((subArray) => {
            subArray.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
         });

         return repeatedItems;
      }

      if (isRepeat && Boolean(submissionId)) {
         const result = getRepeatedQuestions(surveyItem.innerItems);

         const repeatedItems = result.reduce((acc, curr) => {
            const repeatGroupIndex = curr.surveyItem?.answers?.[0]?.answerDetail?.repeatGroup;
            if (repeatGroupIndex == null) return acc;

            if (!acc[repeatGroupIndex]) {
               acc[repeatGroupIndex] = [];
            }

            acc[repeatGroupIndex].push(curr);

            return acc;
         }, []);

         const filteredRepeatedItems = repeatedItems.filter(
            (subArray) => Array.isArray(subArray) && subArray.length > 0
         );

         filteredRepeatedItems.forEach((subArray) => {
            subArray.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
         });

         return filteredRepeatedItems;
      }

      return [...surveyItem.innerItems]?.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
   }, [isRepeat, repeatCount, submissionId, surveyItem.innerItems]);

   const handleShowMoreButton = () => {
      setShowRepeatedGroup((prev) => !prev);
   };

   const shouldDisplayRepeatGroup =
      showRepeatedGroup &&
      isRepeat &&
      Array.isArray(sortedSurveyItems) &&
      Array.isArray(sortedSurveyItems[0]);

   return (
      <Fragment>
         {!hasSkipLogic || isQuestionSubmitted || submissionId ? (
            <div
               key={surveyItem.surveyItem?.name}
               className={`preview-survey-section${
                  isRepeat && !props.sectionInRepeat ? "--repeat" : ""
               } ${shouldDisplayRepeatGroup ? "bottomSpace" : ""}`}
               data-xpath={surveyItem.xpath}
            >
               {
                  <div
                     className={`preview-survey-section--header${isRepeat ? "--repeat" : ""} ${
                        shouldDisplayRepeatGroup ? "hasRepeatGroupShown" : ""
                     } `}
                  >
                     <h5
                        style={{
                           textAlign: isArabicLanguage ? "right" : "left"
                        }}
                     >
                        <span>
                           {!surveyItem.sectionLabel || !toLanguage
                              ? surveyItem.surveyItem?.description
                              : surveyItem.sectionLabel?.description}
                        </span>
                        {!isRepeat && (
                           <span style={{ marginLeft: "13px", fontSize: "16px" }}>
                              <InfoCircleOutlined />
                           </span>
                        )}
                     </h5>

                     <div
                        style={{
                           width: "150px"
                        }}
                     >
                        {isRepeat && (
                           <ShowMoreLessButton
                              onClick={handleShowMoreButton}
                              shouldShowMore={!showRepeatedGroup}
                           />
                        )}
                     </div>
                  </div>
               }
               <div
                  style={{
                     textAlign: isArabicLanguage ? "right" : "left"
                  }}
                  data-xpath={surveyItem.xpath}
               >
                  {isRepeat
                     ? sortedSurveyItems.map((repeatGroupQuestions, i) => {
                          const repeatInstanceIndex = i + 1;

                          return (
                             <div
                                key={i}
                                className={`preview-survey-section--questions_wrapper${
                                   isRepeat ? "--repeat" : ""
                                } ${isRepeat && !showRepeatedGroup ? "hideRepeatedGroup" : ""}`}
                             >
                                {!props.sectionInRepeat && (
                                   <div className="iteration-indicator">{repeatInstanceIndex}.</div>
                                )}
                                {repeatGroupQuestions.map((question, j) => {
                                   const repeatQuestionId = `${surveyItem.surveyItem.name}/${question.id}/${repeatInstanceIndex}`;
                                   const repeatQuestionName = `${question.surveyItem.name}/${repeatInstanceIndex}`;

                                   if (question.itemLabel === "Section") {
                                      return (
                                         <Section
                                            key={j}
                                            hasErrors={hasErrors}
                                            sectionInRepeat={true}
                                            surveyItem={{
                                               ...question,
                                               repeat_count: "1", // to display only one instance of the repeated group because it's actually a section
                                               id: repeatQuestionId,
                                               surveyItem: {
                                                  ...question.surveyItem,
                                                  name: repeatQuestionName
                                               }
                                            }}
                                            submissionId={submissionId}
                                         />
                                      );
                                   } else {
                                      return (
                                         <ItemSelector
                                            key={j}
                                            props={{
                                               ...question.surveyItem,
                                               id: repeatQuestionId,
                                               name: repeatQuestionName,
                                               repeatInstanceIndex
                                            }}
                                            hasErrors={hasErrors}
                                            submissionId={submissionId}
                                            isRepeat={isRepeat}
                                         />
                                      );
                                   }
                                })}
                             </div>
                          );
                       })
                     : sortedSurveyItems.map((item, i) => {
                          if (item.itemLabel === "Section") {
                             return (
                                <Section
                                   key={i}
                                   hasErrors={hasErrors}
                                   surveyItem={item}
                                   submissionId={submissionId}
                                />
                             );
                          } else {
                             return (
                                <div
                                   className={`preview-survey-section--questions_wrapper ${
                                      item?.surveyItem?.questionType ===
                                      InputType.CALCULATE_QUESTION
                                         ? "p-0"
                                         : ""
                                   }`}
                                   key={i}
                                >
                                   <ItemSelector
                                      props={item.surveyItem}
                                      hasErrors={hasErrors}
                                      submissionId={submissionId}
                                      xpath={item.xpath}
                                   />
                                </div>
                             );
                          }
                       })}
               </div>
            </div>
         ) : null}
      </Fragment>
   );
}

export default Section;
