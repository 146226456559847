import https from "../utils/http";

export const httpService = {
   get(path, callback, errCallback, config = {}) {
      return new Promise((resolve, reject) => {
         https
            .get(path, config)
            .then((response) => {
               if (response.data.isSuccess) {
                  callback !== undefined && callback !== null && callback(response.data);
                  resolve(response.data);
               } else {
                  errCallback !== undefined && callback !== null && errCallback(response.data);
                  reject(response.data);
               }
            })
            .catch((error) => {
               errCallback !== undefined && callback !== null && errCallback(error);
               reject(error);
            });
      });
   },
   getFile(path, options, callback, errCallback) {
      return new Promise((resolve, reject) => {
         https
            .get(path, { responseType: "blob", ...(options ?? {}) })
            .then((response) => {
               callback(response);
               resolve(response);
            })
            .catch((error) => {
               if (errCallback) {
                  errCallback(error);
               }
               reject(error);
            });
      });
   },

   post(path, payload, callback, errCallback) {
      return new Promise((resolve, reject) => {
         https
            .post(path, payload)
            .then((response) => {
               if (response.data.isSuccess) {
                  callback !== undefined && callback !== null && callback(response.data);
                  resolve(response.data);
               } else {
                  errCallback !== undefined && callback !== null && errCallback(response.data);
                  reject(response.data);
               }
            })
            .catch((error) => {
               errCallback !== undefined && callback !== null && errCallback(error);
               reject(error);
            });
      });
   },

   put(path, payload, callback, errCallback) {
      return https
         .put(path, payload)
         .then((response) => {
            if (response.data.isSuccess) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         })
         .catch(errCallback);
   },

   upload(path, payload, callback, errCallback) {
      return https
         .post(path, payload, {
            headers: {
               "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl"
            }
         })
         .then((response) => {
            if (response.data.isSuccess) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         });
   },

   uploadFileForQuestion(path, payload, params, callback, errCallback) {
      return https
         .post(path, payload, {
            params: {
               questionId: params.questionId
            },
            headers: {
               "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl"
            }
         })
         .then((response) => {
            if (response.data.isSuccess) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         });
   },

   update(path, payload, callback, errCallback) {
      return https.post(path, payload).then((response) => {
         if (response.data.isSuccess) {
            callback !== undefined && callback !== null && callback(response.data);
         } else {
            errCallback !== undefined && callback !== null && errCallback(response.data);
            return response;
         }
      });
   },

   delete(path, payload, callback, errCallback) {
      return https
         .delete(path, { data: payload })
         .then((response) => {
            if (response.data.isSuccess) {
               callback !== undefined && callback !== null && callback(response.data);
            } else {
               errCallback !== undefined && callback !== null && errCallback(response.data);
               return response;
            }
         })
         .catch(errCallback);
   }
   // delete(path, payload, callback, errCallback) {
   //    // Setting the headers
   //    const config = {
   //       headers: {
   //          "Content-Type": "application/json"
   //       }
   //    };

   //    return https
   //       .delete(path, { data: payload }, config)
   //       .then((response) => {
   //          if (response.data.isSuccess) {
   //             if (callback) {
   //                callback(response.data);
   //             }
   //          } else {
   //             if (errCallback) {
   //                errCallback(response.data);
   //             }
   //          }
   //       })
   //       .catch((error) => {
   //          if (errCallback) {
   //             errCallback(error);
   //          }
   //       });
   // }
};
