//TODO: Refactor this file
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { createQueryParams } from "../../../../helpers/queryParams";
import { httpService } from "../../../../base/services/httpService.service";
import { getTabValue } from "../../../../helpers/getTabValue";

const PAGE_SIZE = 10;

const useHandleTasksData = (baseUrl, formatData) => {
   const [data, setData] = useState([]);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: PAGE_SIZE,
      total: 0
   });
   const [isLoading, setIsLoading] = useState(false);
   const [activeTab, setActiveTab] = useState("All");
   const [showFilters, setShowFilters] = useState(false);
   const [filterValue, setFilterValue] = useState({
      searchInput: "",
      tab: null,
      dataCollectors: null,
      locationGroupId: null,
      surveyId: null,
      surveyName: null,
      collectionType: null,
      taskStatus: null,
      collectionRound: null,
      tasksDeadline: null,
      sendingDate: null,
      gender: null,
      receiver: null
   });

   const fetchData = useCallback(
      async (page, pageSize) => {
         setIsLoading(true);
         const queryParams = createQueryParams(filterValue);
         const additionalParams = new URLSearchParams(queryParams);
         const params = new URLSearchParams({
            isDeleted: false,
            Page: page,
            PageSize: pageSize
         });
         for (const [key, value] of additionalParams) {
            params.append(key, value);
         }
         const url = `${baseUrl}&${params.toString()}`;

         httpService.get(
            url,
            (response) => {
               const formattedData = formatData(response.data);
               setData(formattedData.tasks);
               setPagination((prevState) => ({
                  ...prevState,
                  current: page,
                  pageSize: pageSize,
                  total: formattedData.totalSize
               }));
               setIsLoading(false);
            },
            (_err) => {
               setIsLoading(false);
            }
         );
      },
      [filterValue]
   );

   useEffect(() => {
      if (filterValue.searchInput.trim() === "") {
         fetchData(pagination.current, pagination.pageSize);
         return;
      }

      const timeoutId = setTimeout(() => {
         fetchData(pagination.current, pagination.pageSize);
      }, 500);
      return () => clearTimeout(timeoutId);
   }, [filterValue]);

   const handlePagination = useCallback(
      (page, pageSize) => {
         fetchData(page, pageSize);
         setPagination((prevS) => ({ ...prevS, current: page, pageSize }));
      },
      [pagination]
   );

   const handleSearch = useCallback(
      (e) => {
         setPagination((prevS) => ({ ...prevS, current: 1 }));
         setFilterValue((prevS) => ({ ...prevS, searchInput: e.target.value }));
      },
      [setPagination, setFilterValue]
   );

   const onSelect = useCallback(
      (value, key) => {
         setFilterValue((prevS) => ({ ...prevS, [key]: value }));
         setPagination((prevS) => ({ ...prevS, current: 1 }));
      },
      [filterValue]
   );

   const handleClearFilters = useCallback(() => {
      setFilterValue((prevFilterValue) => ({
         ...prevFilterValue,
         dataCollectors: null,
         locationGroupId: null,
         surveyId: null,
         surveyName: null,
         collectionType: null,
         taskStatus: null,
         collectionRound: null,
         tasksDeadline: null,
         sendingDate: null,
         gender: null,
         receiver: null
      }));
      setPagination((prevS) => ({ ...prevS, current: 1 }));
   }, [pagination]);

   const handleTabChange = useCallback((key) => {
      setActiveTab(key);
      setFilterValue((prevS) => ({ ...prevS, tab: getTabValue(key) }));
      setPagination((prevS) => ({ ...prevS, current: 1 }));
   }, []);

   const handleShowFilters = useCallback(() => {
      setShowFilters((prevState) => !prevState);
   }, []);

   return {
      data,
      setData,
      fetchData,
      pagination,
      isLoading,
      setIsLoading,
      filterValue,
      handleSearch,
      handlePagination,
      onSelect,
      handleClearFilters,
      activeTab,
      showFilters,
      handleTabChange,
      handleShowFilters
   };
};

export default useHandleTasksData;
