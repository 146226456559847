import { Button, Col, Radio, Row, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { httpService } from "../../base/services/httpService.service";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { PlanDetail } from "../../pages/auth/register/components/planSection/PlanDetail";
import BasicModal from "../../ui/BasicModal/BasicModal";
import useNotification from "../../base/hooks/useNotification";
import { ReactComponent as StarRewardRate } from "../../assets/icons/star-reward-rating-rate.svg";
import { ReactComponent as StarRewardBadge } from "../../assets/icons/star-award-reward-badge.svg";

const SubscriptionPlanBoxes = ({
   planName,
   subscriptionStatus,
   subscriptionId,
   price,
   isMonthly
}) => {
   const { openNotification } = useNotification();
   const [planOptions, setPlanOptions] = useState([]);
   const [selectedPlan, setSelectedPlan] = useState({
      name: planName,
      price: price
   });
   const [isLoading, setIsLoading] = useState(false);
   const [openPackageModal, setOpenPackageModal] = useState(false);
   const [selectedPlanToChange, setSelectedPlanToChange] = useState({
      plan: null,
      planPeriodEnd: false
   });
   const [isPlanChanging, setIsPlanChanging] = useState(false);
   const [isYearly, setIsYearly] = useState(!isMonthly);

   useEffect(() => {
      setIsLoading(true);
      httpService
         .get("/Account/getPackets", (res) => {
            setPlanOptions(res.data);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   const handlePlanSelection = (plan, isSelected) => {
      if ((plan.name === selectedPlan.name && isSelected) || subscriptionStatus === "canceled")
         return;
      setOpenPackageModal(true);
      setSelectedPlanToChange({ planPeriodEnd: null, plan: plan });
   };

   const changePlan = () => {
      setIsPlanChanging(true);
      const params = new URLSearchParams({
         stripeSubscriptionId: subscriptionId,
         newPacketId: selectedPlanToChange.plan.id,
         updatePlanAfterPeriodEnds: selectedPlanToChange.planPeriodEnd
      });
      httpService
         .get(`/Payment/updateSubscriptionPlan?${params.toString()}`, (response) => {
            if (!response) return;
            openNotification("success", "", response.message);
            if (selectedPlanToChange.planPeriodEnd) return;
            else {
               setSelectedPlan({
                  name: selectedPlanToChange.plan.name,
                  price: isYearly
                     ? selectedPlanToChange.plan.annuallyPrice
                     : selectedPlanToChange.plan.monthlyPrice
               });
            }
         })
         .catch((error) => {
            if (
               error.response.data.errors &&
               error.response.data.errors[0].includes("Cannot change subscription")
            ) {
               openNotification("error", "", error.response.data.errors[0]);
            } else {
               openNotification("error", "", "Something went wrong. Please try again.");
            }
         })
         .finally(() => {
            setOpenPackageModal(false);
            setIsPlanChanging(false);
         });
   };

   const handleSelectedPackage = (e) => {
      setSelectedPlanToChange((prevState) => ({
         ...prevState,
         planPeriodEnd: e.target.value
      }));
   };

   const handlePricingToggle = (e) => {
      setIsYearly(e.target.value === "yearly");
      localStorage.setItem("isYearly", e.target.value === "yearly");
   };

   return (
      <>
         {isLoading ? (
            <Spin
               style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px"
               }}
            ></Spin>
         ) : (
            <div className="subscriptionPlanBoxes">
               <Row>
                  <Col xs={24} sm={18} md={16} lg={16} xl={12}>
                     <h6>Current Subscription</h6>
                  </Col>
                  <Col className="columnPosition" xs={24} sm={18} md={16} lg={16} xl={12}>
                     <Radio.Group
                        onChange={handlePricingToggle}
                        value={isYearly ? "yearly" : "monthly"}
                     >
                        <Radio value="yearly">Billed annually</Radio>
                        <Radio value="monthly">Billed monthly</Radio>
                     </Radio.Group>
                  </Col>
               </Row>
               <Row className="mt-8 mb-8" justify="space-between" gutter={[24, 24]}>
                  {planOptions.map((plan, i) => {
                     const isSelected =
                        selectedPlan.name === plan.name &&
                        (isYearly
                           ? selectedPlan.price === plan.annuallyPrice
                           : selectedPlan.price === plan.monthlyPrice);
                     return (
                        <Col xs={24} sm={18} md={16} lg={16} xl={11} key={plan.id}>
                           <div className={`subscriptionPlans ${isSelected ? "selected" : ""}`}>
                              <div className="planTitle">
                                 <span className="months">
                                    {isYearly ? "1 month free" : "1 month free"}
                                 </span>
                                 <span
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       flexWrap: "wrap"
                                    }}
                                 >
                                    {plan.name === "Standard" ? (
                                       <StarRewardBadge />
                                    ) : (
                                       <StarRewardRate />
                                    )}
                                    <b>{plan.name === "Standard" ? "Starter" : "Premium"} </b>
                                    &nbsp; package
                                 </span>
                              </div>
                              <div className="payment">
                                 <span>USD</span>{" "}
                                 <span className="annuallyPrice">
                                    ${isYearly ? plan.annuallyPrice : plan.monthlyPrice}
                                 </span>
                                 <span style={{ color: "#999999" }}> per month</span>
                              </div>
                              <div className="content">
                                 <p
                                    style={{
                                       color: isSelected ? "#DE8344" : "#9BACEB",
                                       fontFamily: "Poppins",
                                       fontSize: "14px",
                                       fontWeight: "600"
                                    }}
                                 >
                                    This plans includes:
                                 </p>
                                 <p>
                                    <AiOutlineCheckCircle />
                                    &nbsp;
                                    <b>
                                       {plan.workspaceLimit != 0
                                          ? plan.workspaceLimit
                                          : "Unlimited"}{" "}
                                    </b>
                                    workspaces
                                 </p>
                                 <p>
                                    <AiOutlineCheckCircle />
                                    &nbsp;
                                    <b>
                                       {plan.membersLimit != 0 ? plan.membersLimit : "Unlimited"}{" "}
                                    </b>
                                    users/workspace
                                 </p>
                                 <p>
                                    <AiOutlineCheckCircle />
                                    &nbsp;
                                    {plan.databaseLimit != 0 ? (
                                       <>
                                          <b>{plan?.databaseLimit} GB</b> data storage limit
                                       </>
                                    ) : (
                                       <>
                                          <b>Unlimited</b> data storage
                                       </>
                                    )}
                                 </p>
                              </div>
                              <PlanDetail planData={plan} />
                              <Button
                                 type="default"
                                 className={`defaultButton ${
                                    isSelected ? "currentPlan" : "choosePlan"
                                 }`}
                                 disabled={isSelected}
                                 onClick={() => handlePlanSelection(plan, isSelected)}
                              >
                                 {isSelected ? "Current plan" : "Choose this plan"}
                              </Button>
                           </div>
                        </Col>
                     );
                  })}
                  {openPackageModal && (
                     <BasicModal
                        isModalOpen={openPackageModal}
                        handleCancel={() => setOpenPackageModal(false)}
                        footerButtonCustomStyle
                     >
                        <div className="mt-5 mb-8">
                           <h4>How would you like to update your subscription plan?</h4>
                        </div>
                        <Radio.Group
                           onChange={handleSelectedPackage}
                           value={selectedPlanToChange.planPeriodEnd}
                        >
                           <Space direction="vertical">
                              <Radio value={true}>
                                 Update the plan after the current subscription period ends
                              </Radio>
                              <Radio value={false}>Update the plan immediately</Radio>
                           </Space>
                        </Radio.Group>
                        <Row gutter={16} className="mt-8" justify="center" align="middle">
                           <Col>
                              <Button
                                 className="cancel"
                                 onClick={() => setOpenPackageModal(false)}
                                 disabled={isPlanChanging}
                              >
                                 Cancel
                              </Button>
                           </Col>
                           <Col>
                              <Button
                                 onClick={changePlan}
                                 className="primaryButton"
                                 loading={isPlanChanging}
                              >
                                 Continue
                              </Button>
                           </Col>
                        </Row>
                     </BasicModal>
                  )}
               </Row>
            </div>
         )}
      </>
   );
};

export default SubscriptionPlanBoxes;
