import React, { Fragment, useEffect, useMemo } from "react";
import { Radio, Select } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import useEvaluateChoiceFilter from "../../question-preview/hooks/useEvaluateChoiceFilter";
import useEvaluateAppearance from "../../question-preview/hooks/useEvaluateAppearance";
import { getForeignInputNames } from "../../question-preview/helpers/getForeignInputNames";
import useInputValuesByName from "../../question-preview/hooks/useInputValueByName";
import { REGEX } from "../../../../../../../helpers/constraintParsers";

import "./SelectOne.scss";

export const SelectOne = React.memo(
   ({
      props,
      hasErrors,
      surveyId,
      userId,
      language,
      submissionId,
      label,
      hint,
      defaultValue: calcDefaultValue
   }) => {
      const { id, selectQuestionChoices, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const answer = answers?.find((answer) => answer?.submissionId === submissionId);

      const choices =
         answer?.answerDetail?.choices?.length > 0 && answer?.answerDetail?.choices[0].choiceId;

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = submissionId && choices ? choices : null;

      const passConstraint = useEvaluateConstraint(props.constraint, inputState?.skipLogicAnswer);

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.choices?.length === 0);

      const defaultValue = hasSubmissionId
         ? submissionAnswer
         : inputState?.answerDetail?.choices[0];

      const derivedOptions = useEvaluateChoiceFilter({
         choiceFilter: hasSubmissionId ? null : props.choiceFilter,
         name: props.name,
         selectQuestionChoices,
         repeatInstanceIndex: props.repeatInstanceIndex,
         isRepeat: !!props.repeatInstanceIndex
      });

      const appearance = useEvaluateAppearance({
         appearance: props.appearance
      });

      const inputNames = getForeignInputNames(
         derivedOptions.map((option) => option.choiceLabels[0]?.text).join(" ")
      );
      const hasInputs = inputNames.length > 0;
      const inputValuesObject = useInputValuesByName(inputNames);

      const selectOptions = useMemo(() => {
         return derivedOptions.map((option) => {
            const translatedLabel =
               option.choiceLabels.find((choice) => choice.languageId === language)?.text ||
               option.choiceLabels[0]?.text;

            const foreignValueLabel = translatedLabel.replace(
               REGEX.foreignInputValue,
               (_, inputName) => inputValuesObject[inputName] ?? "[No value]"
            );

            return {
               value: option.choiceLabels[0]?.choiceId,
               name: option.name,
               label: hasInputs ? foreignValueLabel : translatedLabel
            };
         });
      }, [derivedOptions, hasInputs, inputValuesObject, language]);

      const handleCheckBoxChange = (selectedValue) => {
         if (hasSubmissionId) {
            return;
         }

         if (selectedValue === defaultValue) {
            setInputs({ target: { name: id, value: "" } });
         } else {
            const selectedChoice = selectQuestionChoices.find((choice) =>
               [choice.id, choice.name].includes(selectedValue)
            );

            const numberValue = Number(selectedChoice.name);
            const parsedValue = isNaN(numberValue) ? selectedChoice.name : numberValue;

            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     name: props.name,
                     skipLogicAnswer: parsedValue,
                     questionType: questionType,
                     answerDetail: {
                        choices: [
                           props.default || calcDefaultValue ? selectedChoice.id : selectedValue
                        ]
                     }
                  }
               }
            });
         }
      };

      useEffect(() => {
         const value = props.default || calcDefaultValue;
         if (value) {
            handleCheckBoxChange(value);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.default, calcDefaultValue]);

      useEffect(() => {
         if (props.choiceFilter && !derivedOptions.find((opt) => opt.id === defaultValue)) {
            setInputs({ target: { name: id, value: "" } });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [derivedOptions, id, props.choiceFilter, defaultValue]);

      return (
         <div className="select_one-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div
               className={`select_one-submission-type--options${
                  !appearance.includes("minimal") &&
                  (showErrors || (passConstraint !== null && !passConstraint))
                     ? "_error"
                     : ""
               }`}
            >
               {appearance.includes("minimal") ? (
                  <Select
                     listHeight={300}
                     allowClear
                     showSearch={false}
                     size="large"
                     status={
                        showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""
                     }
                     placeholder="none selected"
                     value={defaultValue}
                     onChange={(value) => handleCheckBoxChange(value || defaultValue)}
                     options={selectOptions}
                  />
               ) : (
                  <Fragment>
                     {selectOptions.map((option) => (
                        <Radio
                           key={option.value}
                           prefixCls="select_one-submission-type--options---radio"
                           checked={option.value === defaultValue}
                           value={option.value}
                           className={`${hasSubmissionId ? "submissionStyle" : ""}`}
                           onClick={() => {
                              handleCheckBoxChange(option.value);
                           }}
                        >
                           {option.label}
                        </Radio>
                     ))}
                  </Fragment>
               )}
            </div>

            <span className="select_one-submission-type--error">
               {showErrors ? "You must select one" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
