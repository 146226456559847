import React, { Fragment, useMemo } from "react";
import Section from "../editor-types/section/Section";
import {
   Note,
   Number,
   SelectMultiple,
   SelectOne,
   Text,
   Photograph,
   Date,
   DateTime,
   Decimal,
   Time,
   PhoneNumber,
   Acknowledge,
   RangeQuestion,
   Ranking,
   Rating,
   Matrix
} from "../submisson-types";
import { useParams } from "react-router-dom";
import { LocalStorageService } from "../../../../../../base/services/local-storage.service";
import { useTranslateStore } from "../../../surveyStore";
import useInputValuesByName from "./hooks/useInputValueByName";
import useEvaluateConstraint from "./hooks/useEvaluateConstraint";
import CalculateQuestion from "../editor-types/calculate/CalculateQuestion";
import GPS from "../editor-types/gps/GPS";
import { getForeignInputNames } from "./helpers/getForeignInputNames";
import { REGEX } from "../../../../../../helpers/constraintParsers";
import { InputType } from "../../../../../../utils/enums";

import "./ItemSelector.scss";

const components = {
   [InputType.SECTION]: Section, // TODO: is this needed?
   [InputType.TEXT]: Text,
   [InputType.SELECT_ONE]: SelectOne,
   [InputType.SELECT_MULTIPLE]: SelectMultiple,
   [InputType.NUMBER]: Number,
   [InputType.DECIMAL]: Decimal,
   [InputType.DATE]: Date,
   [InputType.TIME]: Time,
   [InputType.NOTE]: Note,
   [InputType.PHONE_NUMBER]: PhoneNumber,
   [InputType.DATE_TIME]: DateTime,
   [InputType.GPS]: GPS, // not supported yet
   [InputType.PHOTOGRAPH]: Photograph,
   [InputType.FILE]: Photograph, // Explain: InputType.FILE has the same functionality as InputType.PHOTOGRAPH
   [InputType.ACKNOWLEDGE]: Acknowledge,
   [InputType.RANGE_QUESTION]: RangeQuestion,
   [InputType.MATRIX]: Matrix,
   [InputType.RANKING]: Ranking,
   [InputType.RATING]: Rating,
   [InputType.CALCULATE_QUESTION]: CalculateQuestion,
   [InputType.HIDDEN]: CalculateQuestion // Explain: InputType.HIDDEN has the same functionality as InputType.CALCULATE_QUESTION
};

function ItemSelector({ props, hasErrors, submissionId, isRepeat }) {
   const { questionType, relevant: hasSkipLogic } = props;
   const userId = LocalStorageService?.getItem("user")?.id || "";
   const { toLanguage } = useTranslateStore();
   const isComponentAvailable = components.hasOwnProperty(questionType);
   const { id: surveyId } = useParams();
   const DynamicComponent = components[questionType];

   const inputNames = getForeignInputNames(
      `${props.questionLabels?.[0]?.text ?? ""} ${props?.surveyQuestionHints?.[0]?.text ?? ""}`
   );
   const hasInputs = inputNames.length > 0;
   const inputValuesObject = useInputValuesByName(
      isRepeat ? inputNames.map((name) => `${name}/${props.repeatInstanceIndex}`) : inputNames
   );

   const label = useMemo(() => {
      const label = props.questionLabels
         ? props.questionLabels.find((question) => question.languageId === toLanguage)?.text ??
           props.questionLabels[0]?.text
         : "( No label )";

      return hasInputs
         ? label.replace(REGEX.foreignInputValue, (match, inputName) => {
              const value =
                 inputValuesObject[
                    isRepeat ? `${inputName}/${props.repeatInstanceIndex}` : inputName
                 ];
              return `${value ?? "[No value]"}`;
           })
         : label;
   }, [
      hasInputs,
      inputValuesObject,
      isRepeat,
      props.questionLabels,
      props.repeatInstanceIndex,
      toLanguage
   ]);

   const hint = useMemo(() => {
      const hint =
         props?.surveyQuestionHints?.find(({ languageId }) => languageId === toLanguage)?.text ??
         props?.surveyQuestionHints?.[0]?.text;

      return hasInputs
         ? hint?.replace(REGEX.foreignInputValue, (match, inputName) => {
              const value =
                 inputValuesObject[
                    isRepeat ? `${inputName}/${props.repeatInstanceIndex}` : inputName
                 ];
              return `${value ?? "[No value]"}`;
           })
         : hint;
   }, [
      hasInputs,
      inputValuesObject,
      isRepeat,
      props.repeatInstanceIndex,
      props?.surveyQuestionHints,
      toLanguage
   ]);

   // default value is ignored for these components : Text, SelectOne, SelectMultiple, Note, Hidden
   const defaultValue = useEvaluateConstraint(
      [
         InputType.TEXT,
         InputType.SELECT_ONE,
         InputType.SELECT_MULTIPLE,
         InputType.NOTE,
         InputType.HIDDEN
      ].includes(questionType)
         ? null
         : props?.default,
      true
   );

   const calculatedValue = useEvaluateConstraint(props?.calculation, true, {
      repeatInstanceIndex: props.repeatInstanceIndex,
      isRepeat: isRepeat
   });

   const isQuestionSubmitted = useEvaluateConstraint(
      isRepeat
         ? props?.relevant?.replace(
              REGEX.foreignInputValue,
              `${"${"}$1/${props.repeatInstanceIndex}}`
           )
         : props?.relevant,
      true,
      {
         repeatInstanceIndex: props.repeatInstanceIndex,
         isRepeat: isRepeat
      }
   );

   return (
      <Fragment>
         {!hasSkipLogic || isQuestionSubmitted || submissionId ? (
            <div
               key={"parent-item" + props?.id}
               data-id={props?.id}
               className={`preview-item-selector${isRepeat ? "--repeat" : ""}`}
               data-xpath={props.xpath}
            >
               {isComponentAvailable && (
                  <DynamicComponent
                     hasErrors={hasErrors}
                     submissionId={submissionId}
                     props={props}
                     surveyId={surveyId}
                     userId={userId}
                     language={toLanguage}
                     label={label}
                     hint={hint}
                     defaultValue={calculatedValue ?? defaultValue}
                  />
               )}
            </div>
         ) : null}
      </Fragment>
   );
}

export default ItemSelector;
